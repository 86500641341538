import networksToChains from "@util/networksToChains";
import { Chain } from "../util/constants";
import useAvailableNetworks from "./useAvailableNetworks";

/**
 * Returns a sorted list of chains the user is allowed choose from in selectors
 */
export default function useAvailableChains(): Chain[] {
  return networksToChains(useAvailableNetworks());
}
