import { NetworkId } from "@alch/dx-webapp-api/shared";
import useAvailableNetworks from "@hooks/useAvailableNetworks";
import { SearchParam } from "@hooks/useSearchParam";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  Availability,
  Chain,
  Network,
  NETWORK_INFO,
  NetworkInfo,
} from "@util/constants";
import { useCallback } from "react";
import { z } from "zod";

const NETWORK_INFO_QUERY_KEY = ["network-info"];

/**
 * Until we hook up the backend, simulate fetching network info
 */
const simulateGetNetworkInfo = async () => {
  console.log("calling simulateGetNetworkInfo...");
  const searchParams = new URLSearchParams(window.location.search);
  await new Promise((r) => setTimeout(r, 600));
  if (searchParams.get(SearchParam.TestUseNetworkError)) {
    throw new Error("Simulating use network error");
  }
  return Object.values(NETWORK_INFO);
};

/**
 * Returns a list of NetworkInfo objects for networks that the user is allowed to see.
 * Deprecated networks are excluded by default.
 */
export function useNetworkInfos(options?: {
  chainEnum?: Chain;
  includeDeprecated?: boolean;
}): UseQueryResult<NetworkInfo[], Error> {
  const chainEnum = options?.chainEnum;
  const includeDeprecated = options?.includeDeprecated ?? false;
  const availableNetworks = useAvailableNetworks({ includeDeprecated: true });

  const select = useCallback(
    (networkInfos: NetworkInfo[]) =>
      filterNetworkInfos(networkInfos, {
        chainEnum,
        includeDeprecated,
        // TODO: Remove this when plugging into the backend, which will filter flagged networks
        networkEnums: availableNetworks,
      }),
    [chainEnum, includeDeprecated, availableNetworks],
  );

  return useQuery({
    queryKey: NETWORK_INFO_QUERY_KEY,
    queryFn: simulateGetNetworkInfo,
    select,
  });
}

/**
 * Fetch a single NetworkInfo, throwing a query error if its not found
 */
export function useNetworkInfo(
  network: Network,
): UseQueryResult<NetworkInfo, Error> {
  const select = useCallback(
    (networkInfos: NetworkInfo[]) =>
      selectNetworkFromNetworkInfo(networkInfos, network),
    [network],
  );

  return useQuery({
    queryKey: NETWORK_INFO_QUERY_KEY,
    queryFn: simulateGetNetworkInfo,
    select,
  });
}

export function createNetworkValidator(networkInfos: NetworkInfo[]) {
  return z.string().refine((networkId): networkId is NetworkId => {
    return networkInfos.some((info) => info.id === networkId);
  });
}

function filterNetworkInfos(
  networkInfos: NetworkInfo[],
  options: {
    networkEnums?: Network[];
    chainEnum?: Chain;
    includeDeprecated?: boolean;
  },
) {
  const { networkEnums, chainEnum, includeDeprecated } = options;

  return networkInfos
    .filter((networkInfo) =>
      chainEnum !== undefined ? networkInfo.chain === chainEnum : true,
    )
    .filter((networkInfo) =>
      includeDeprecated
        ? true
        : networkInfo.availability !== Availability.DEPRECATED,
    )
    .filter(
      (networkInfo) =>
        networkEnums === undefined ||
        networkEnums.includes(networkInfo.network),
    );
}

function selectNetworkFromNetworkInfo(
  networkInfos: NetworkInfo[],
  network: Network,
) {
  const networkInfo = networkInfos.find(
    (networkInfo) => networkInfo.network === network,
  );
  if (!networkInfo) {
    // Make useQuery throw an error if the network is not found as if
    // it was a network error
    throw new Error(`Network ${network} not found`);
  }
  return networkInfo;
}
