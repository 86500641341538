import { normalizeParamName } from "../lib/params";

export const buildName = (
  ...parts: Array<string | number | null | undefined>
) => {
  return parts
    .filter((x) => x != null)
    .map((name) => normalizeParamName(String(name)))
    .join(".");
};

export const getNestedIndexClassName = (nestedIndex: number) => {
  return nestedIndex === 0
    ? "bg-grayscale-100/50"
    : nestedIndex === 1
      ? "bg-grayscale-200/50"
      : "bg-grayscale-200";
};
