import { useWaffleFlags } from "@queries/useWaffleFlags";
import { compact, uniq } from "lodash";
import { useMemo } from "react";
import {
  Availability,
  Chain,
  NETWORKS,
  NETWORK_INFO,
  Network,
} from "../util/constants";

const NETWORK_WAFFLE_FLAGS = uniq(
  compact(NETWORKS.map((network) => NETWORK_INFO[network].publicOverrideFlag)),
);

/**
 * Returns a list of networks the user is allowed choose from in selectors
 *
 * @deprecated Use the `useNetworkInfos()` hook instead
 */
export default function useAvailableNetworks(options?: {
  includeDeprecated?: boolean;
  chain?: Chain;
}): Network[] {
  const flagValues = useWaffleFlags(NETWORK_WAFFLE_FLAGS);

  return useMemo(
    () =>
      NETWORKS.filter((network) => {
        const { availability, publicOverrideFlag, chain } =
          NETWORK_INFO[network];

        // If options.chain is provided, exclude networks on other chains
        const filterByChain = options?.chain;
        if (filterByChain !== undefined && chain !== filterByChain) {
          return false;
        }

        switch (availability) {
          case Availability.PRERELEASE:
            // Prerelease networks are only available by feature flag
            return (
              publicOverrideFlag !== undefined && flagValues[publicOverrideFlag]
            );
          case Availability.PUBLIC:
            return true;
          case Availability.DEPRECATED:
            // Exclude deprecated networks by default, users generally should not be able to create new
            // things (apps, webhooks) with deprecated networks.
            return options?.includeDeprecated ?? false;
          default:
            // Unknown availability state
            return false;
        }
      }),
    [flagValues, options?.chain, options?.includeDeprecated],
  );
}
