import { Chain, ChainId, Network, NetworkId } from "@alch/dx-webapp-api/shared";
import { sortBy } from "lodash";
import { ProtoChain, ProtoNetwork } from "./protobufs";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { WaffleFlag } from "@queries/useWaffleFlags";

export { Chain, Network };

export const FREE_TIER_CU_LIMIT = 300000000;
export const GROWTH_TIER_CU_LIMIT = 400000000;
export const GROWTH_TIER_MONTHLY_BASE_PRICE = 49;
export const GROWTH_TIER_CU_PRICE_PER_CU = 0.0000012;

export const FREE_TIER_CUS_LABEL = FREE_TIER_CU_LIMIT.toLocaleString();
export const GROWTH_TIER_CUS_LABEL = GROWTH_TIER_CU_LIMIT.toLocaleString();

// used on SignUp app, SignupShareView.tsx
export const REINFORCED_TRANSACTIONS_COST_PER_APP = 999;

// Deprecated
export type NetworkEnum = ProtoNetwork;

export enum SubscriptionTier {
  FREE = 0,
  GROWTH = 1,
  ENTERPRISE = 2,
  OTHER = 3,
  SCALE = 4,
}

export const SUBSCRIPTION_TIER_LABELS: Record<SubscriptionTier, string> = {
  [SubscriptionTier.FREE]: "Free",
  [SubscriptionTier.GROWTH]: "Growth",
  [SubscriptionTier.SCALE]: "Scale",
  [SubscriptionTier.ENTERPRISE]: "Enterprise",
  [SubscriptionTier.OTHER]: "Enterprise Trial",
};

export enum PlanTerm {
  MONTHLY = 0,
  ANNUAL = 1,
}

export enum UsageCapType {
  AUTO_SCALE = 0,
  CAPPED = 1,
}

export enum WebhookType {
  DROPPED_TRANSACTION = 1,
  MINED_TRANSACTION = 2,
  ADDRESS_ACTIVITY = 4,
  NFT_ACTIVITY = 6,
  NFT_METADATA_UPDATE = 7, // Deprecated for new networks
  GRAPHQL = 8,

  // Not supported
  PENDING_TRANSACTION = 0,
  DELAYED_TRANSACTION = 3,
  GAS_PRICE = 5,
}

export enum Availability {
  PRERELEASE = "prerelease",
  PUBLIC = "public",
  DEPRECATED = "deprecated",
}

const CHAIN_SORT_ORDER = [
  Chain.WORLDCHAIN,
  Chain.SHAPE,
  Chain.ETH,
  Chain.ZKSYNC,
  Chain.OPT,
  Chain.MATIC,
  Chain.STARKNET,
  Chain.ARB,
  Chain.ARBNOVA,
  Chain.ASTAR,
  Chain.POLYGONZKEVM,
  Chain.ZETACHAIN,
  Chain.FANTOM,
  Chain.MANTLE,
  Chain.BERACHAIN,
  Chain.BLAST,
  Chain.LINEA,
  Chain.ZORA,
  Chain.POLYNOMIAL,
  Chain.BASE,
  Chain.SCROLL,
  Chain.GNOSIS,
  Chain.FRAX,
  Chain.BNB,
  Chain.AVAX,
  Chain.SOLANA,
  Chain.CELO,
  Chain.METIS,
  Chain.OPBNB,
  Chain.ALCHEMY,
  Chain.BOB,
  Chain.CROSSFI,
  Chain.IOTA,
  Chain.MODE,
  Chain.MOONBASE,
  Chain.MOONBEAM,
  Chain.MOONRIVER,
  Chain.PALM,
  Chain.SHIDEN,
];

export interface ChainInfo {
  // Chain enum value used by frontend
  chain: Chain;
  // Alchemy-wide ID of the chain
  id: ChainId;
  // Blockchain enum value used in protobufs
  value: ProtoChain;
  // Display name
  name: string;
  // Description used for marketing purposes
  description: string;
  // All networks that make up this chain, even if not public or deprecated
  networks: Network[];
  networkIds: NetworkId[];
  // Network that is chosen for this chain by default
  defaultNetwork: Network;
  // Order to display the chain in selectors
  order: number;
}

export const CHAIN_INFO: Record<Chain, ChainInfo> = {
  [Chain.ETH]: {
    chain: Chain.ETH,
    id: "ETH",
    value: ProtoChain.ETH,
    name: "Ethereum",
    description: "The decentralized L1",
    networks: [
      Network.ETH_MAINNET,
      Network.ETH_ROPSTEN,
      Network.ETH_RINKEBY,
      Network.ETH_KOVAN,
      Network.ETH_GOERLI,
      Network.ETH_SEPOLIA,
      Network.ETH_HOLESKY,
    ],
    networkIds: [
      "ETH_MAINNET",
      "ETH_ROPSTEN",
      "ETH_RINKEBY",
      "ETH_KOVAN",
      "ETH_GOERLI",
      "ETH_SEPOLIA",
      "ETH_HOLESKY",
    ],
    defaultNetwork: Network.ETH_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ETH),
  },
  [Chain.ARB]: {
    chain: Chain.ARB,
    id: "ARB",
    value: ProtoChain.ARB,
    name: "Arbitrum",
    description: "Instant L1 interoperability",
    networks: [
      Network.ARB_MAINNET,
      Network.ARB_RINKEBY,
      Network.ARB_GOERLI,
      Network.ARB_SEPOLIA,
    ],
    networkIds: ["ARB_MAINNET", "ARB_RINKEBY", "ARB_GOERLI", "ARB_SEPOLIA"],
    defaultNetwork: Network.ARB_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ARB),
  },
  [Chain.MATIC]: {
    chain: Chain.MATIC,
    id: "MATIC",
    value: ProtoChain.MATIC,
    name: "Polygon PoS",
    description: "Low fees, high throughput",
    networks: [Network.MATIC_MAINNET, Network.MATIC_MUMBAI, Network.MATIC_AMOY],
    networkIds: ["MATIC_MAINNET", "MATIC_MUMBAI", "MATIC_AMOY"],
    defaultNetwork: Network.MATIC_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MATIC),
  },
  [Chain.POLYGONZKEVM]: {
    chain: Chain.POLYGONZKEVM,
    id: "POLYGONZKEVM",
    value: ProtoChain.POLYGONZKEVM,
    name: "Polygon zkEVM",
    description: "Scalable security",
    networks: [
      Network.POLYGONZKEVM_MAINNET,
      Network.POLYGONZKEVM_TESTNET,
      Network.POLYGONZKEVM_CARDONA,
    ],
    networkIds: [
      "POLYGONZKEVM_MAINNET",
      "POLYGONZKEVM_TESTNET",
      "POLYGONZKEVM_CARDONA",
    ],
    defaultNetwork: Network.POLYGONZKEVM_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.POLYGONZKEVM),
  },
  [Chain.OPT]: {
    chain: Chain.OPT,
    id: "OPT",
    value: ProtoChain.OPT,
    name: "Optimism",
    description: "Ultra fast, EVM-compatible",
    networks: [
      Network.OPT_MAINNET,
      Network.OPT_KOVAN,
      Network.OPT_GOERLI,
      Network.OPT_SEPOLIA,
    ],
    networkIds: ["OPT_MAINNET", "OPT_KOVAN", "OPT_GOERLI", "OPT_SEPOLIA"],
    defaultNetwork: Network.OPT_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.OPT),
  },
  [Chain.SOLANA]: {
    chain: Chain.SOLANA,
    id: "SOLANA",
    value: ProtoChain.SOLANA,
    name: "Solana",
    description: "Powerful, efficient transactions",
    networks: [Network.SOLANA_MAINNET, Network.SOLANA_DEVNET],
    networkIds: ["SOLANA_MAINNET", "SOLANA_DEVNET"],
    defaultNetwork: Network.SOLANA_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.SOLANA),
  },
  [Chain.ASTAR]: {
    chain: Chain.ASTAR,
    id: "ASTAR",
    value: ProtoChain.ASTAR,
    name: "Astar",
    description: "Connecting Polkadot to all L1s",
    networks: [Network.ASTAR_MAINNET],
    networkIds: ["ASTAR_MAINNET"],
    defaultNetwork: Network.ASTAR_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ASTAR),
  },
  [Chain.STARKNET]: {
    chain: Chain.STARKNET,
    id: "STARKNET",
    value: ProtoChain.STARKNET,
    name: "Starknet",
    description: "Performance built on Zk-Rollups",
    networks: [
      Network.STARKNET_MAINNET,
      Network.STARKNET_GOERLI,
      Network.STARKNET_SEPOLIA,
    ],
    networkIds: ["STARKNET_MAINNET", "STARKNET_GOERLI", "STARKNET_SEPOLIA"],
    defaultNetwork: Network.STARKNET_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.STARKNET),
  },
  [Chain.BASE]: {
    chain: Chain.BASE,
    id: "BASE",
    value: ProtoChain.BASE,
    name: "Base",
    description: "Secure, affordable and developer-centric",
    networks: [Network.BASE_MAINNET, Network.BASE_GOERLI, Network.BASE_SEPOLIA],
    networkIds: ["BASE_MAINNET", "BASE_GOERLI", "BASE_SEPOLIA"],
    defaultNetwork: Network.BASE_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BASE),
  },
  [Chain.ZKSYNC]: {
    chain: Chain.ZKSYNC,
    id: "ZKSYNC",
    value: ProtoChain.ZKSYNC,
    name: "ZKsync",
    description: "The elastic chain",
    networks: [Network.ZKSYNC_MAINNET, Network.ZKSYNC_SEPOLIA],
    networkIds: ["ZKSYNC_MAINNET", "ZKSYNC_SEPOLIA"],
    defaultNetwork: Network.ZKSYNC_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ZKSYNC),
  },
  [Chain.ZORA]: {
    chain: Chain.ZORA,
    id: "ZORA",
    value: ProtoChain.ZORA,
    name: "Zora",
    description: "Create and connect onchain",
    networks: [Network.ZORA_MAINNET, Network.ZORA_SEPOLIA],
    networkIds: ["ZORA_MAINNET", "ZORA_SEPOLIA"],
    defaultNetwork: Network.ZORA_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ZORA),
  },
  [Chain.FRAX]: {
    chain: Chain.FRAX,
    id: "FRAX",
    value: ProtoChain.FRAX,
    name: "Frax",
    description: "The decentralized central bank of crypto",
    networks: [Network.FRAX_MAINNET, Network.FRAX_SEPOLIA],
    networkIds: ["FRAX_MAINNET", "FRAX_SEPOLIA"],
    defaultNetwork: Network.FRAX_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.FRAX),
  },
  [Chain.BNB]: {
    chain: Chain.BNB,
    id: "BNB",
    value: ProtoChain.BNB,
    name: "BNB Smart Chain",
    description: "Build apps effortlessly",
    networks: [Network.BNB_MAINNET, Network.BNB_TESTNET],
    networkIds: ["BNB_MAINNET", "BNB_TESTNET"],
    defaultNetwork: Network.BNB_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BNB),
  },
  [Chain.AVAX]: {
    chain: Chain.AVAX,
    id: "AVAX",
    value: ProtoChain.AVAX,
    name: "Avalanche",
    description: "Deploy powerful, scalable onchain apps",
    networks: [Network.AVAX_MAINNET, Network.AVAX_FUJI],
    networkIds: ["AVAX_MAINNET", "AVAX_FUJI"],
    defaultNetwork: Network.AVAX_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.AVAX),
  },
  [Chain.ARBNOVA]: {
    chain: Chain.ARBNOVA,
    id: "ARBNOVA",
    value: ProtoChain.ARBNOVA,
    name: "Arbitrum Nova",
    description: "Scale smart with AnyTrust",
    networks: [Network.ARBNOVA_MAINNET],
    networkIds: ["ARBNOVA_MAINNET"],
    defaultNetwork: Network.ARBNOVA_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ARBNOVA),
  },
  [Chain.ALCHEMY]: {
    chain: Chain.ALCHEMY,
    id: "ALCHEMY",
    value: ProtoChain.ALCHEMY,
    name: "Alchemy",
    description: "",
    networks: [
      Network.ALCHEMY_MAINNET,
      Network.ALCHEMY_SEPOLIA,
      Network.ALCHEMY_INTERNAL,
    ],
    networkIds: ["ALCHEMY_MAINNET", "ALCHEMY_SEPOLIA", "ALCHEMY_INTERNAL"],
    defaultNetwork: Network.ALCHEMY_SEPOLIA, // Update to ALCHEMY_MAINNET when it launches
    order: CHAIN_SORT_ORDER.indexOf(Chain.ALCHEMY),
  },
  [Chain.WORLDCHAIN]: {
    chain: Chain.WORLDCHAIN,
    id: "WORLDCHAIN",
    value: ProtoChain.WORLDCHAIN,
    name: "World Chain",
    description: "A blockchain for humans",
    networks: [Network.WORLDCHAIN_MAINNET, Network.WORLDCHAIN_SEPOLIA],
    networkIds: ["WORLDCHAIN_MAINNET", "WORLDCHAIN_SEPOLIA"],
    defaultNetwork: Network.WORLDCHAIN_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.WORLDCHAIN),
  },
  [Chain.ZETACHAIN]: {
    chain: Chain.ZETACHAIN,
    id: "ZETACHAIN",
    value: ProtoChain.ZETACHAIN,
    name: "ZetaChain",
    description: "Unleashing universal apps",
    networks: [Network.ZETACHAIN_MAINNET, Network.ZETACHAIN_TESTNET],
    networkIds: ["ZETACHAIN_MAINNET", "ZETACHAIN_TESTNET"],
    defaultNetwork: Network.ZETACHAIN_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.ZETACHAIN),
  },
  [Chain.BLAST]: {
    chain: Chain.BLAST,
    id: "BLAST",
    value: ProtoChain.BLAST,
    name: "Blast",
    description: "The fullstack chain",
    networks: [Network.BLAST_MAINNET, Network.BLAST_SEPOLIA],
    networkIds: ["BLAST_MAINNET", "BLAST_SEPOLIA"],
    defaultNetwork: Network.BLAST_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BLAST),
  },
  [Chain.SCROLL]: {
    chain: Chain.SCROLL,
    id: "SCROLL",
    value: ProtoChain.SCROLL,
    name: "Scroll",
    description: "Ethereum's native zkEVM L2",
    networks: [Network.SCROLL_MAINNET, Network.SCROLL_SEPOLIA],
    networkIds: ["SCROLL_MAINNET", "SCROLL_SEPOLIA"],
    defaultNetwork: Network.SCROLL_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.SCROLL),
  },
  [Chain.LINEA]: {
    chain: Chain.LINEA,
    id: "LINEA",
    value: ProtoChain.LINEA,
    name: "Linea",
    description: "Where apps thrive",
    networks: [Network.LINEA_MAINNET, Network.LINEA_SEPOLIA],
    networkIds: ["LINEA_MAINNET", "LINEA_SEPOLIA"],
    defaultNetwork: Network.LINEA_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.LINEA),
  },
  [Chain.GNOSIS]: {
    chain: Chain.GNOSIS,
    id: "GNOSIS",
    value: ProtoChain.GNOSIS,
    name: "Gnosis",
    description: "The community-run chain",
    networks: [Network.GNOSIS_MAINNET, Network.GNOSIS_CHIADO],
    networkIds: ["GNOSIS_MAINNET", "GNOSIS_CHIADO"],
    defaultNetwork: Network.GNOSIS_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.GNOSIS),
  },
  [Chain.FANTOM]: {
    chain: Chain.FANTOM,
    id: "FANTOM",
    value: ProtoChain.FANTOM,
    name: "Fantom Opera",
    description: "Unleash innovation",
    networks: [Network.FANTOM_MAINNET, Network.FANTOM_TESTNET],
    networkIds: ["FANTOM_MAINNET", "FANTOM_TESTNET"],
    defaultNetwork: Network.FANTOM_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.FANTOM),
  },
  [Chain.MANTLE]: {
    chain: Chain.MANTLE,
    id: "MANTLE",
    value: ProtoChain.MANTLE,
    name: "Mantle",
    description: "The rollup for mass adoption",
    networks: [Network.MANTLE_MAINNET, Network.MANTLE_SEPOLIA],
    networkIds: ["MANTLE_MAINNET", "MANTLE_SEPOLIA"],
    defaultNetwork: Network.MANTLE_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MANTLE),
  },
  [Chain.CELO]: {
    chain: Chain.CELO,
    id: "CELO",
    value: ProtoChain.CELO,
    name: "Celo",
    description: "The regenerative rollup",
    networks: [Network.CELO_MAINNET, Network.CELO_ALFAJORES],
    networkIds: ["CELO_MAINNET", "CELO_ALFAJORES"],
    defaultNetwork: Network.CELO_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.CELO),
  },
  [Chain.BERACHAIN]: {
    chain: Chain.BERACHAIN,
    id: "BERACHAIN",
    value: ProtoChain.BERACHAIN,
    name: "Berachain",
    description: "An EVM-identical L1 powered by Proof Of Liquidity",
    networks: [Network.BERACHAIN_ARTIO],
    networkIds: ["BERACHAIN_ARTIO"],
    defaultNetwork: Network.BERACHAIN_ARTIO,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BERACHAIN),
  },
  [Chain.METIS]: {
    chain: Chain.METIS,
    id: "METIS",
    value: ProtoChain.METIS,
    name: "Metis",
    description: "Accelerating web3",
    networks: [Network.METIS_MAINNET],
    networkIds: ["METIS_MAINNET"],
    defaultNetwork: Network.METIS_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.METIS),
  },
  [Chain.OPBNB]: {
    chain: Chain.OPBNB,
    id: "OPBNB",
    value: ProtoChain.OPBNB,
    name: "opBNB",
    description: "Lower fees, higher throughput. Full BNB chain potential",
    networks: [Network.OPBNB_MAINNET, Network.OPBNB_TESTNET],
    networkIds: ["OPBNB_MAINNET", "OPBNB_TESTNET"],
    defaultNetwork: Network.OPBNB_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.OPBNB),
  },
  [Chain.POLYNOMIAL]: {
    chain: Chain.POLYNOMIAL,
    id: "POLYNOMIAL",
    value: ProtoChain.POLYNOMIAL,
    name: "Polynomial",
    description: "The derivatives superchain",
    networks: [Network.POLYNOMIAL_MAINNET, Network.POLYNOMIAL_SEPOLIA],
    networkIds: ["POLYNOMIAL_MAINNET", "POLYNOMIAL_SEPOLIA"],
    defaultNetwork: Network.POLYNOMIAL_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.POLYNOMIAL),
  },
  [Chain.SHAPE]: {
    chain: Chain.SHAPE,
    id: "SHAPE",
    value: ProtoChain.SHAPE,
    name: "Shape",
    description: "The chain for creators",
    networks: [Network.SHAPE_MAINNET, Network.SHAPE_SEPOLIA],
    networkIds: ["SHAPE_MAINNET", "SHAPE_SEPOLIA"],
    defaultNetwork: Network.SHAPE_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.SHAPE),
  },
  [Chain.BOB]: {
    chain: Chain.BOB,
    id: "BOB",
    value: ProtoChain.BOB,
    name: "BOB",
    description: "",
    networks: [Network.BOB_MAINNET],
    networkIds: ["BOB_MAINNET"],
    defaultNetwork: Network.BOB_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BOB),
  },
  [Chain.CROSSFI]: {
    chain: Chain.CROSSFI,
    id: "CROSSFI",
    value: ProtoChain.CROSSFI,
    name: "CrossFi",
    description: "",
    networks: [Network.CROSSFI_TESTNET],
    networkIds: ["CROSSFI_TESTNET"],
    defaultNetwork: Network.CROSSFI_TESTNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.BOB),
  },
  [Chain.IOTA]: {
    chain: Chain.IOTA,
    id: "IOTA",
    value: ProtoChain.IOTA,
    name: "Iota",
    description: "",
    networks: [Network.IOTA_MAINNET, Network.IOTA_TESTNET],
    networkIds: ["IOTA_MAINNET", "IOTA_TESTNET"],
    defaultNetwork: Network.IOTA_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.IOTA),
  },
  [Chain.MODE]: {
    chain: Chain.MODE,
    id: "MODE",
    value: ProtoChain.MODE,
    name: "Mode",
    description: "",
    networks: [Network.MODE_MAINNET, Network.MODE_SEPOLIA],
    networkIds: ["MODE_MAINNET", "MODE_SEPOLIA"],
    defaultNetwork: Network.MODE_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MODE),
  },
  [Chain.MOONBASE]: {
    chain: Chain.MOONBASE,
    id: "MOONBASE",
    value: ProtoChain.MOONBASE,
    name: "Moonbase",
    description: "",
    networks: [Network.MOONBASE_ALPHA],
    networkIds: ["MOONBASE_ALPHA"],
    defaultNetwork: Network.MOONBASE_ALPHA,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MOONBASE),
  },
  [Chain.MOONBEAM]: {
    chain: Chain.MOONBEAM,
    id: "MOONBEAM",
    value: ProtoChain.MOONBEAM,
    name: "Moonbeam",
    description: "",
    networks: [Network.MOONBEAM_MAINNET],
    networkIds: ["MOONBEAM_MAINNET"],
    defaultNetwork: Network.MOONBEAM_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MOONBEAM),
  },
  [Chain.MOONRIVER]: {
    chain: Chain.MOONRIVER,
    id: "MOONRIVER",
    value: ProtoChain.MOONRIVER,
    name: "Moonriver",
    description: "",
    networks: [Network.MOONRIVER_MAINNET],
    networkIds: ["MOONRIVER_MAINNET"],
    defaultNetwork: Network.MOONRIVER_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.MOONRIVER),
  },
  [Chain.PALM]: {
    chain: Chain.PALM,
    id: "PALM",
    value: ProtoChain.PALM,
    name: "Palm",
    description: "",
    networks: [Network.PALM_MAINNET, Network.PALM_TESTNET],
    networkIds: ["PALM_MAINNET", "PALM_TESTNET"],
    defaultNetwork: Network.PALM_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.PALM),
  },
  [Chain.SHIDEN]: {
    chain: Chain.SHIDEN,
    id: "SHIDEN",
    value: ProtoChain.SHIDEN,
    name: "Shiden",
    description: "",
    networks: [Network.SHIDEN_MAINNET, Network.SHIDEN_SHIBUYA],
    networkIds: ["SHIDEN_MAINNET", "SHIDEN_SHIBUYA"],
    defaultNetwork: Network.SHIDEN_MAINNET,
    order: CHAIN_SORT_ORDER.indexOf(Chain.SHIDEN),
  },
};

const NETWORK_SORT_ORDER = [
  Network.ETH_MAINNET,
  Network.ETH_SEPOLIA,
  Network.ETH_HOLESKY,
  Network.ETH_GOERLI,
  Network.ETH_RINKEBY,
  Network.ETH_ROPSTEN,
  Network.ETH_KOVAN,
  Network.MATIC_MAINNET,
  Network.MATIC_AMOY,
  Network.MATIC_MUMBAI,
  Network.ARB_MAINNET,
  Network.ARB_SEPOLIA,
  Network.ARB_GOERLI,
  Network.ARB_RINKEBY,
  Network.OPT_MAINNET,
  Network.OPT_SEPOLIA,
  Network.OPT_KOVAN,
  Network.OPT_GOERLI,
  Network.POLYGONZKEVM_MAINNET,
  Network.POLYGONZKEVM_CARDONA,
  Network.POLYGONZKEVM_TESTNET,
  Network.BASE_MAINNET,
  Network.BASE_SEPOLIA,
  Network.BASE_GOERLI,
  Network.STARKNET_MAINNET,
  Network.STARKNET_SEPOLIA,
  Network.STARKNET_GOERLI,
  Network.SOLANA_MAINNET,
  Network.SOLANA_DEVNET,
  Network.ASTAR_MAINNET,
  Network.ZKSYNC_MAINNET,
  Network.ZKSYNC_SEPOLIA,
  Network.ZORA_MAINNET,
  Network.ZORA_SEPOLIA,
  Network.FRAX_MAINNET,
  Network.FRAX_SEPOLIA,
  Network.BNB_MAINNET,
  Network.BNB_TESTNET,
  Network.AVAX_MAINNET,
  Network.AVAX_FUJI,
  Network.ARBNOVA_MAINNET,
  Network.ALCHEMY_MAINNET,
  Network.ALCHEMY_SEPOLIA,
  Network.ALCHEMY_INTERNAL,
  Network.WORLDCHAIN_MAINNET,
  Network.WORLDCHAIN_SEPOLIA,
  Network.ZETACHAIN_MAINNET,
  Network.ZETACHAIN_TESTNET,
  Network.BLAST_MAINNET,
  Network.BLAST_SEPOLIA,
  Network.SCROLL_MAINNET,
  Network.SCROLL_SEPOLIA,
  Network.LINEA_MAINNET,
  Network.LINEA_SEPOLIA,
  Network.GNOSIS_MAINNET,
  Network.GNOSIS_CHIADO,
  Network.FANTOM_MAINNET,
  Network.FANTOM_TESTNET,
  Network.MANTLE_MAINNET,
  Network.MANTLE_SEPOLIA,
  Network.CELO_MAINNET,
  Network.CELO_ALFAJORES,
  Network.BERACHAIN_ARTIO,
  Network.METIS_MAINNET,
  Network.OPBNB_MAINNET,
  Network.OPBNB_TESTNET,
  Network.POLYNOMIAL_MAINNET,
  Network.POLYNOMIAL_SEPOLIA,
  Network.SHAPE_MAINNET,
  Network.SHAPE_SEPOLIA,
  Network.BOB_MAINNET,
  Network.CROSSFI_TESTNET,
  Network.IOTA_MAINNET,
  Network.IOTA_TESTNET,
  Network.MODE_MAINNET,
  Network.MODE_SEPOLIA,
  Network.MOONBASE_ALPHA,
  Network.MOONBEAM_MAINNET,
  Network.MOONRIVER_MAINNET,
  Network.PALM_MAINNET,
  Network.PALM_TESTNET,
  Network.SHIDEN_MAINNET,
  Network.SHIDEN_SHIBUYA,
];

export interface NetworkInfo {
  id: NetworkId;
  network: Network;
  chainId: ChainId;
  chain: Chain;
  // Display name
  name: string;
  // Kebab-case version of the API endpoint like eth-mainnet
  kebabCaseId: string;
  // URL of the public block explorer
  explorerUrl: string;
  // Chain ID of the network as defined by EIP-155 (see chainlist.org)
  // Non-EVM chains may not have a chain ID
  networkChainId?: number;
  // Release status of the network
  availability: Availability;
  // Feature flag that will make network show up even if isPublic is false
  publicOverrideFlag?: WaffleFlag;
  // Whether network is accessible only to paid teams
  isPaidOnly: boolean;
  // List of webhook types that can be created for this network
  supportedWebhookTypes: WebhookType[];
  // Enum used in the alchemy-sdk-js package, if supported (https://github.com/alchemyplatform/alchemy-sdk-js)
  sdkEnum?: string;
  // [Deprecated] Enum used in our protobufs
  protoNetwork: ProtoNetwork;
  // A map of custom { [version]: endpoint } pairs, defaults to { v2: 'v2' }
  apiVersionEndpoints?: Record<string, string>;
  // Gas Manager enabled for this network
  supportsGasPolicies: boolean;
  // Full URL of our official alchemy faucet for this network, if it exists
  faucetUrl?: string;
  // Whether the network is a testnet or not
  isTestNet: boolean;
  // Order to display the network in selectors
  order: number;
  // Currency used to denote gas & transaction value
  currency: string;
  // Transactions show in the mempool
  supportsMempool: boolean;
  // Apps with this chain support our reinforced transactions addon
  supportsReinforcedTxns: boolean;
  // Apps with this chain support websockets
  supportsWebsockets: boolean;
  // API for this chain is limited to only AA methods
  supportsOnlyAccountAbstraction?: boolean;
  // URL to the alchemy docs
  docsUrl: string;
}

export const NETWORK_INFO: Record<Network, NetworkInfo> = {
  [Network.ETH_MAINNET]: {
    id: "ETH_MAINNET",
    network: Network.ETH_MAINNET,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Mainnet",
    kebabCaseId: "eth-mainnet",
    explorerUrl: "https://etherscan.io",
    networkChainId: 1,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.NFT_METADATA_UPDATE,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ETH_MAINNET,
    sdkEnum: "ETH_MAINNET",
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_ROPSTEN]: {
    id: "ETH_ROPSTEN",
    network: Network.ETH_ROPSTEN,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Ropsten",
    kebabCaseId: "eth-ropsten",
    explorerUrl: "https://ropsten.etherscan.io",
    networkChainId: 3,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ETH_ROPSTEN,
    sdkEnum: "ETH_ROPSTEN",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_ROPSTEN),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_RINKEBY]: {
    id: "ETH_RINKEBY",
    network: Network.ETH_RINKEBY,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Rinkeby",
    kebabCaseId: "eth-rinkeby",
    explorerUrl: "https://rinkeby.etherscan.io",
    networkChainId: 4,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ETH_RINKEBY,
    sdkEnum: "ETH_RINKEBY",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_RINKEBY),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_KOVAN]: {
    id: "ETH_KOVAN",
    network: Network.ETH_KOVAN,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Kovan",
    kebabCaseId: "eth-kovan",
    explorerUrl: "https://kovan.etherscan.io",
    networkChainId: 42,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ETH_KOVAN,
    sdkEnum: "ETH_KOVAN",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_KOVAN),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_GOERLI]: {
    id: "ETH_GOERLI",
    network: Network.ETH_GOERLI,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Goerli",
    kebabCaseId: "eth-goerli",
    explorerUrl: "https://goerli.etherscan.io",
    networkChainId: 5,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ETH_GOERLI,
    sdkEnum: "ETH_GOERLI",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_GOERLI),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_SEPOLIA]: {
    id: "ETH_SEPOLIA",
    network: Network.ETH_SEPOLIA,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Sepolia",
    kebabCaseId: "eth-sepolia",
    explorerUrl: "https://sepolia.etherscan.io",
    networkChainId: 11155111,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ETH_SEPOLIA,
    sdkEnum: "ETH_SEPOLIA",
    faucetUrl: "https://www.alchemy.com/faucets/ethereum-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.ETH_HOLESKY]: {
    id: "ETH_HOLESKY",
    network: Network.ETH_HOLESKY,
    chainId: "ETH",
    chain: Chain.ETH,
    name: "Ethereum Holešky",
    kebabCaseId: "eth-holesky",
    explorerUrl: "https://holesky.etherscan.io",
    networkChainId: 17000,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.EthHolesky,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ETH_HOLESKY,
    sdkEnum: "ETH_HOLESKY",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ETH_HOLESKY),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: true,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  },
  [Network.OPT_MAINNET]: {
    id: "OPT_MAINNET",
    network: Network.OPT_MAINNET,
    chainId: "OPT",
    chain: Chain.OPT,
    name: "Optimism Mainnet",
    kebabCaseId: "opt-mainnet",
    explorerUrl: "https://optimistic.etherscan.io",
    networkChainId: 10,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.OPT_MAINNET,
    sdkEnum: "OPT_MAINNET",
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPT_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/optimism-api-quickstart",
  },
  [Network.OPT_KOVAN]: {
    id: "OPT_KOVAN",
    network: Network.OPT_KOVAN,
    chainId: "OPT",
    chain: Chain.OPT,
    name: "Optimism Kovan",
    kebabCaseId: "opt-kovan",
    explorerUrl: "https://kovan-optimistic.etherscan.io",
    networkChainId: 69,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.OPT_KOVAN,
    sdkEnum: "OPT_KOVAN",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPT_KOVAN),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/optimism-api-quickstart",
  },
  [Network.OPT_GOERLI]: {
    id: "OPT_GOERLI",
    network: Network.OPT_GOERLI,
    chainId: "OPT",
    chain: Chain.OPT,
    name: "Optimism Goerli",
    kebabCaseId: "opt-goerli",
    explorerUrl: "https://goerli-optimism.etherscan.io",
    networkChainId: 420,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.OPT_GOERLI,
    sdkEnum: "OPT_GOERLI",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPT_GOERLI),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/optimism-api-quickstart",
  },
  [Network.OPT_SEPOLIA]: {
    id: "OPT_SEPOLIA",
    network: Network.OPT_SEPOLIA,
    chainId: "OPT",
    chain: Chain.OPT,
    name: "Optimism Sepolia",
    kebabCaseId: "opt-sepolia",
    explorerUrl: "https://sepolia-optimism.etherscan.io",
    networkChainId: 11155420,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.OPT_SEPOLIA,
    sdkEnum: "OPT_SEPOLIA",
    faucetUrl: "https://www.alchemy.com/faucets/optimism-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPT_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/optimism-api-quickstart",
  },
  [Network.ARB_MAINNET]: {
    id: "ARB_MAINNET",
    network: Network.ARB_MAINNET,
    chainId: "ARB",
    chain: Chain.ARB,
    name: "Arbitrum Mainnet",
    kebabCaseId: "arb-mainnet",
    explorerUrl: "https://explorer.arbitrum.io",
    networkChainId: 42161,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ARB_MAINNET,
    sdkEnum: "ARB_MAINNET",
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ARB_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/arbitrum-api-quickstart",
  },
  [Network.ARB_RINKEBY]: {
    id: "ARB_RINKEBY",
    network: Network.ARB_RINKEBY,
    chainId: "ARB",
    chain: Chain.ARB,
    name: "Arbitrum Rinkeby",
    kebabCaseId: "arb-rinkeby",
    explorerUrl: "https://rinkeby-explorer.arbitrum.io",
    networkChainId: 421611,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ARB_RINKEBY,
    sdkEnum: "ARB_RINKEBY",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ARB_RINKEBY),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/arbitrum-api-quickstart",
  },
  [Network.ARB_GOERLI]: {
    id: "ARB_GOERLI",
    network: Network.ARB_GOERLI,
    chainId: "ARB",
    chain: Chain.ARB,
    name: "Arbitrum Goerli",
    kebabCaseId: "arb-goerli",
    explorerUrl: "https://goerli-explorer.arbitrum.io",
    networkChainId: 421613,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ARB_GOERLI,
    sdkEnum: "ARB_GOERLI",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ARB_GOERLI),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/arbitrum-api-quickstart",
  },
  [Network.ARB_SEPOLIA]: {
    id: "ARB_SEPOLIA",
    network: Network.ARB_SEPOLIA,
    chainId: "ARB",
    chain: Chain.ARB,
    name: "Arbitrum Sepolia",
    kebabCaseId: "arb-sepolia",
    explorerUrl: "https://sepolia-explorer.arbitrum.io/",
    networkChainId: 421614,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ARB_SEPOLIA,
    sdkEnum: "ARB_SEPOLIA",
    faucetUrl: "https://www.alchemy.com/faucets/arbitrum-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ARB_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/arbitrum-api-quickstart",
  },
  [Network.MATIC_MAINNET]: {
    id: "MATIC_MAINNET",
    network: Network.MATIC_MAINNET,
    chainId: "MATIC",
    chain: Chain.MATIC,
    name: "Polygon Mainnet",
    kebabCaseId: "polygon-mainnet",
    explorerUrl: "https://polygonscan.com",
    networkChainId: 137,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.NFT_METADATA_UPDATE,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.MATIC_MAINNET,
    sdkEnum: "MATIC_MAINNET",
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.MATIC_MAINNET),
    currency: "MATIC",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/polygon-api-quickstart",
  },
  [Network.MATIC_MUMBAI]: {
    id: "MATIC_MUMBAI",
    network: Network.MATIC_MUMBAI,
    chainId: "MATIC",
    chain: Chain.MATIC,
    name: "Polygon Mumbai",
    kebabCaseId: "polygon-mumbai",
    explorerUrl: "https://mumbai.polygonscan.com",
    networkChainId: 80001,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MATIC_MUMBAI,
    sdkEnum: "MATIC_MUMBAI",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.MATIC_MUMBAI),
    currency: "MATIC",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/polygon-api-quickstart",
  },
  [Network.MATIC_AMOY]: {
    id: "MATIC_AMOY",
    network: Network.MATIC_AMOY,
    chainId: "MATIC",
    chain: Chain.MATIC,
    name: "Polygon Amoy",
    kebabCaseId: "polygon-amoy",
    explorerUrl: "https://www.oklink.com/amoy",
    networkChainId: 80002,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.MINED_TRANSACTION,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
      WebhookType.GRAPHQL,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.MATIC_AMOY,
    sdkEnum: "MATIC_AMOY",
    faucetUrl: "https://www.alchemy.com/faucets/polygon-amoy",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.MATIC_AMOY),
    currency: "MATIC",
    supportsMempool: true,
    supportsReinforcedTxns: true,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/polygon-api-quickstart",
  },
  [Network.SOLANA_MAINNET]: {
    id: "SOLANA_MAINNET",
    network: Network.SOLANA_MAINNET,
    chainId: "SOLANA",
    chain: Chain.SOLANA,
    name: "Solana Mainnet",
    kebabCaseId: "solana-mainnet",
    explorerUrl: "https://explorer.solana.com",
    networkChainId: undefined, // Not EVM
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    sdkEnum: "",
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SOLANA_MAINNET,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.SOLANA_MAINNET),
    currency: "SOL",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/solana-api-quickstart",
  },
  [Network.SOLANA_DEVNET]: {
    id: "SOLANA_DEVNET",
    network: Network.SOLANA_DEVNET,
    chainId: "SOLANA",
    chain: Chain.SOLANA,
    name: "Solana Devnet",
    kebabCaseId: "solana-devnet",
    explorerUrl: "https://explorer.solana.com/?cluster=devnet",
    networkChainId: undefined, // Not EVM
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    sdkEnum: "",
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SOLANA_DEVNET,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.SOLANA_DEVNET),
    currency: "SOL",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/solana-api-quickstart",
  },
  [Network.ASTAR_MAINNET]: {
    id: "ASTAR_MAINNET",
    network: Network.ASTAR_MAINNET,
    chainId: "ASTAR",
    chain: Chain.ASTAR,
    name: "Astar Mainnet",
    kebabCaseId: "astar-mainnet",
    explorerUrl: "https://astar.subscan.io/",
    networkChainId: 592,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ASTAR_MAINNET,
    sdkEnum: "ASTAR_MAINNET",
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ASTAR_MAINNET),
    currency: "ASTR",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/astar-api-quickstart",
  },
  [Network.POLYGONZKEVM_MAINNET]: {
    id: "POLYGONZKEVM_MAINNET",
    network: Network.POLYGONZKEVM_MAINNET,
    chainId: "POLYGONZKEVM",
    chain: Chain.POLYGONZKEVM,
    name: "Polygon zkEVM Mainnet",
    kebabCaseId: "polygonzkevm-mainnet",
    explorerUrl: "https://explorer.public.zkevm-test.net/",
    networkChainId: 1101,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.POLYGONZKEVM_MAINNET,
    sdkEnum: "", // L1 so no SDK support
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.POLYGONZKEVM_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/polygon-zkevm-api-quickstart",
  },
  [Network.POLYGONZKEVM_TESTNET]: {
    id: "POLYGONZKEVM_TESTNET",
    network: Network.POLYGONZKEVM_TESTNET,
    chainId: "POLYGONZKEVM",
    chain: Chain.POLYGONZKEVM,
    name: "Polygon zkEVM Testnet",
    kebabCaseId: "polygonzkevm-testnet",
    explorerUrl: "https://testnet-zkevm.polygonscan.com/",
    networkChainId: 1442,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.POLYGONZKEVM_TESTNET,
    sdkEnum: "", // L1 so no SDK support
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.POLYGONZKEVM_TESTNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/polygon-zkevm-api-quickstart",
  },
  [Network.POLYGONZKEVM_CARDONA]: {
    id: "POLYGONZKEVM_CARDONA",
    network: Network.POLYGONZKEVM_CARDONA,
    chainId: "POLYGONZKEVM",
    chain: Chain.POLYGONZKEVM,
    name: "Polygon zkEVM Cardona",
    kebabCaseId: "polygonzkevm-cardona",
    explorerUrl: "https://cardona-zkevm.polygonscan.com/",
    networkChainId: 2442,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.POLYGONZKEVM_CARDONA,
    sdkEnum: "", // L1 so no SDK support
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.POLYGONZKEVM_CARDONA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/polygon-zkevm-api-quickstart",
  },
  [Network.STARKNET_MAINNET]: {
    id: "STARKNET_MAINNET",
    network: Network.STARKNET_MAINNET,
    chainId: "STARKNET",
    chain: Chain.STARKNET,
    name: "Starknet Mainnet",
    kebabCaseId: "starknet-mainnet",
    explorerUrl: "https://voyager.online/",
    networkChainId: undefined, // non-EVM, but has chain id 0x534e5f4d41494e
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.STARKNET_MAINNET,
    sdkEnum: "", // no SDK support
    apiVersionEndpoints: {
      v0_7: "/starknet/version/rpc/v0_7",
      "v0.6": "/starknet/version/rpc/v0.6",
      "v0.5": "/starknet/version/rpc/v0.5",
      "v0.4": "/starknet/version/rpc/v0.4",
    },
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.STARKNET_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/starknet-api-quickstart",
  },
  [Network.STARKNET_GOERLI]: {
    id: "STARKNET_GOERLI",
    network: Network.STARKNET_GOERLI,
    chainId: "STARKNET",
    chain: Chain.STARKNET,
    name: "Starknet Goerli",
    kebabCaseId: "starknet-goerli",
    explorerUrl: "https://goerli.voyager.online/",
    networkChainId: undefined, // non-EVM, but has chain id 0x534e5f474f45524c49
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.STARKNET_GOERLI,
    sdkEnum: "", // no SDK support
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.STARKNET_GOERLI),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/starknet-api-quickstart",
  },
  [Network.STARKNET_SEPOLIA]: {
    id: "STARKNET_SEPOLIA",
    network: Network.STARKNET_SEPOLIA,
    chainId: "STARKNET",
    chain: Chain.STARKNET,
    name: "Starknet Sepolia",
    kebabCaseId: "starknet-sepolia",
    explorerUrl: "https://sepolia.voyager.online/",
    networkChainId: undefined, // non-EVM
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.STARKNET_SEPOLIA,
    sdkEnum: "", // no SDK support
    apiVersionEndpoints: {
      v0_7: "/starknet/version/rpc/v0_7",
      "v0.6": "/starknet/version/rpc/v0.6",
      "v0.5": "/starknet/version/rpc/v0.5",
      "v0.4": "/starknet/version/rpc/v0.4",
    },
    faucetUrl: "https://www.alchemy.com/faucets/starknet-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.STARKNET_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/starknet-api-quickstart",
  },
  [Network.BASE_MAINNET]: {
    id: "BASE_MAINNET",
    network: Network.BASE_MAINNET,
    chainId: "BASE",
    chain: Chain.BASE,
    name: "Base Mainnet",
    kebabCaseId: "base-mainnet",
    explorerUrl: "https://basescan.org",
    networkChainId: 8453,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.MINED_TRANSACTION,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.BASE_MAINNET,
    sdkEnum: "", // no SDK support
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.BASE_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/base-api-quickstart",
  },
  [Network.BASE_GOERLI]: {
    id: "BASE_GOERLI",
    network: Network.BASE_GOERLI,
    chainId: "BASE",
    chain: Chain.BASE,
    name: "Base Goerli",
    kebabCaseId: "base-goerli",
    explorerUrl: "https://goerli.basescan.org",
    networkChainId: 84531,
    availability: Availability.DEPRECATED,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BASE_GOERLI,
    sdkEnum: "", // no SDK support
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BASE_GOERLI),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/base-api-quickstart",
  },
  [Network.BASE_SEPOLIA]: {
    id: "BASE_SEPOLIA",
    network: Network.BASE_SEPOLIA,
    chainId: "BASE",
    chain: Chain.BASE,
    name: "Base Sepolia",
    kebabCaseId: "base-sepolia",
    explorerUrl: "https://sepolia.basescan.org",
    networkChainId: 84532,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.MINED_TRANSACTION,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.BASE_SEPOLIA,
    sdkEnum: "", // no SDK support
    faucetUrl: "https://www.alchemy.com/faucets/base-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BASE_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/base-api-quickstart",
  },
  [Network.ZKSYNC_MAINNET]: {
    id: "ZKSYNC_MAINNET",
    network: Network.ZKSYNC_MAINNET,
    chainId: "ZKSYNC",
    chain: Chain.ZKSYNC,
    name: "ZKsync Mainnet",
    kebabCaseId: "zksync-mainnet",
    explorerUrl: "https://explorer.zksync.io",
    networkChainId: 324,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ZKsync,
    supportedWebhookTypes: [
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.MINED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ZKSYNC_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZKSYNC_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/zksync-api-quickstart",
  },
  [Network.ZKSYNC_SEPOLIA]: {
    id: "ZKSYNC_SEPOLIA",
    network: Network.ZKSYNC_SEPOLIA,
    chainId: "ZKSYNC",
    chain: Chain.ZKSYNC,
    name: "ZKsync Sepolia",
    kebabCaseId: "zksync-sepolia",
    explorerUrl: "https://sepolia.explorer.zksync.io/",
    networkChainId: 300,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ZKsync,
    supportedWebhookTypes: [
      WebhookType.DROPPED_TRANSACTION,
      WebhookType.MINED_TRANSACTION,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ZKSYNC_SEPOLIA,
    sdkEnum: undefined,
    faucetUrl: "https://www.alchemy.com/faucets/zksync-sepolia",
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZKSYNC_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "https://docs.alchemy.com/reference/zksync-api-quickstart",
  },
  [Network.ZORA_MAINNET]: {
    id: "ZORA_MAINNET",
    network: Network.ZORA_MAINNET,
    chainId: "ZORA",
    chain: Chain.ZORA,
    name: "Zora Mainnet",
    kebabCaseId: "zora-mainnet",
    explorerUrl: "https://explorer.zora.energy",
    networkChainId: 7777777,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ZORA_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZORA_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "https://docs.alchemy.com/reference/zora-api-quickstart",
  },
  [Network.ZORA_SEPOLIA]: {
    id: "ZORA_SEPOLIA",
    network: Network.ZORA_SEPOLIA,
    chainId: "ZORA",
    chain: Chain.ZORA,
    name: "Zora Sepolia",
    kebabCaseId: "zora-sepolia",
    explorerUrl: "https://sepolia.explorer.zora.energy",
    networkChainId: 999999999,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ZORA_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZORA_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "https://docs.alchemy.com/reference/zora-api-quickstart",
  },
  [Network.FRAX_MAINNET]: {
    id: "FRAX_MAINNET",
    network: Network.FRAX_MAINNET,
    chainId: "FRAX",
    chain: Chain.FRAX,
    name: "Frax Mainnet",
    kebabCaseId: "frax-mainnet",
    explorerUrl: "https://fraxscan.com",
    networkChainId: 252,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.FRAX_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.FRAX_MAINNET),
    currency: "frxETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "https://docs.alchemy.com/reference/frax-api-quickstart",
  },
  [Network.FRAX_SEPOLIA]: {
    id: "FRAX_SEPOLIA",
    network: Network.FRAX_SEPOLIA,
    chainId: "FRAX",
    chain: Chain.FRAX,
    name: "Frax Sepolia",
    kebabCaseId: "frax-sepolia",
    explorerUrl: "https://explorer.testnet-sepolia.frax.com",
    networkChainId: 2522,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.FRAX_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.FRAX_MAINNET),
    currency: "frxETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "https://docs.alchemy.com/reference/frax-api-quickstart",
  },
  [Network.BNB_MAINNET]: {
    id: "BNB_MAINNET",
    network: Network.BNB_MAINNET,
    chainId: "BNB",
    chain: Chain.BNB,
    name: "BNB Smart Chain Mainnet",
    kebabCaseId: "bnb-mainnet",
    explorerUrl: "https://bscscan.com",
    networkChainId: 56,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.BNBMainnet,
    supportedWebhookTypes: [
      WebhookType.GRAPHQL,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BNB_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.BNB_MAINNET),
    currency: "BNB",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    supportsOnlyAccountAbstraction: false,
    docsUrl: "https://docs.alchemy.com/reference/bnb-api-quickstart",
  },
  [Network.BNB_TESTNET]: {
    id: "BNB_TESTNET",
    network: Network.BNB_TESTNET,
    chainId: "BNB",
    chain: Chain.BNB,
    name: "BNB Smart Chain Testnet",
    kebabCaseId: "bnb-testnet",
    explorerUrl: "https://testnet.bscscan.com/",
    networkChainId: 97,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.BNBTestnet,
    supportedWebhookTypes: [
      WebhookType.GRAPHQL,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BNB_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BNB_TESTNET),
    currency: "BNB",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    supportsOnlyAccountAbstraction: false,
    docsUrl: "https://docs.alchemy.com/reference/bnb-api-quickstart",
  },
  [Network.AVAX_MAINNET]: {
    id: "AVAX_MAINNET",
    network: Network.AVAX_MAINNET,
    chainId: "AVAX",
    chain: Chain.AVAX,
    name: "Avalanche Mainnet",
    kebabCaseId: "avax-mainnet",
    explorerUrl: "https://subnets.avax.network/c-chain",
    networkChainId: 43114,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.AvaxMainnet,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.AVAX_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.AVAX_MAINNET),
    currency: "AVAX",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: false,
    docsUrl: "https://docs.alchemy.com/reference/avalanche-api-quickstart",
  },
  [Network.AVAX_FUJI]: {
    id: "AVAX_FUJI",
    network: Network.AVAX_FUJI,
    chainId: "AVAX",
    chain: Chain.AVAX,
    name: "Avalanche Fuji",
    kebabCaseId: "avax-fuji",
    explorerUrl: "https://subnets-test.avax.network/c-chain",
    networkChainId: 43113,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.AvaxFuji,
    supportedWebhookTypes: [
      WebhookType.GRAPHQL,
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.AVAX_FUJI,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.AVAX_FUJI),
    currency: "AVAX",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: false,
    docsUrl: "https://docs.alchemy.com/reference/avalanche-api-quickstart",
  },
  [Network.ARBNOVA_MAINNET]: {
    id: "ARBNOVA_MAINNET",
    network: Network.ARBNOVA_MAINNET,
    chainId: "ARBNOVA",
    chain: Chain.ARBNOVA,
    name: "Arbitrum Nova Mainnet",
    kebabCaseId: "arbnova-mainnet",
    explorerUrl: "https://nova.arbiscan.io/",
    networkChainId: 42170,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ArbNova,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ARBNOVA_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ARBNOVA_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    supportsOnlyAccountAbstraction: false,
    docsUrl: "https://docs.alchemy.com/reference/arbitrum-nova-api-quickstart",
  },
  [Network.ALCHEMY_MAINNET]: {
    id: "ALCHEMY_MAINNET",
    network: Network.ALCHEMY_MAINNET,
    chainId: "ALCHEMY",
    chain: Chain.ALCHEMY,
    name: "Alchemy Mainnet",
    kebabCaseId: "alchemy-mainnet",
    explorerUrl: "",
    networkChainId: undefined, // Unknown at the moment
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.AlchemyMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ALCHEMY_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ALCHEMY_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.ALCHEMY_SEPOLIA]: {
    id: "ALCHEMY_SEPOLIA",
    network: Network.ALCHEMY_SEPOLIA,
    chainId: "ALCHEMY",
    chain: Chain.ALCHEMY,
    name: "Alchemy Sepolia",
    kebabCaseId: "alchemy-sepolia",
    explorerUrl: "",
    networkChainId: 69420,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.AlchemySepolia,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ALCHEMY_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ALCHEMY_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.ALCHEMY_INTERNAL]: {
    id: "ALCHEMY_INTERNAL",
    network: Network.ALCHEMY_INTERNAL,
    chainId: "ALCHEMY",
    chain: Chain.ALCHEMY,
    name: "Alchemy Internal",
    kebabCaseId: "alchemy-internal",
    explorerUrl: "",
    networkChainId: 69421,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.AlchemyInternal,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.ALCHEMY_INTERNAL,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ALCHEMY_INTERNAL),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.WORLDCHAIN_MAINNET]: {
    id: "WORLDCHAIN_MAINNET",
    network: Network.WORLDCHAIN_MAINNET,
    chainId: "WORLDCHAIN",
    chain: Chain.WORLDCHAIN,
    name: "World Chain Mainnet",
    kebabCaseId: "worldchain-mainnet",
    explorerUrl: "",
    networkChainId: 3917,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.WorldChainMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.WORLDCHAIN_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.WORLDCHAIN_MAINNET),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.WORLDCHAIN_SEPOLIA]: {
    id: "WORLDCHAIN_SEPOLIA",
    network: Network.WORLDCHAIN_SEPOLIA,
    chainId: "WORLDCHAIN",
    chain: Chain.WORLDCHAIN,
    name: "World Chain Sepolia",
    kebabCaseId: "worldchain-sepolia",
    explorerUrl: "",
    networkChainId: 484752,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.WorldChainSepolia,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.WORLDCHAIN_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.WORLDCHAIN_SEPOLIA),
    currency: "ETH",
    supportsMempool: true,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.ZETACHAIN_MAINNET]: {
    id: "ZETACHAIN_MAINNET",
    network: Network.ZETACHAIN_MAINNET,
    chainId: "ZETACHAIN",
    chain: Chain.ZETACHAIN,
    name: "ZetaChain Mainnet",
    kebabCaseId: "zetachain-mainnet",
    explorerUrl: "",
    networkChainId: 7000,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ZetaChain,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ZETACHAIN_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZETACHAIN_MAINNET),
    currency: "ZETA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.ZETACHAIN_TESTNET]: {
    id: "ZETACHAIN_TESTNET",
    network: Network.ZETACHAIN_TESTNET,
    chainId: "ZETACHAIN",
    chain: Chain.ZETACHAIN,
    name: "ZetaChain Testnet",
    kebabCaseId: "zetachain-testnet",
    explorerUrl: "",
    networkChainId: 7001,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ZetaChain,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.ZETACHAIN_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.ZETACHAIN_TESTNET),
    currency: "ZETA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.BLAST_MAINNET]: {
    id: "BLAST_MAINNET",
    network: Network.BLAST_MAINNET,
    chainId: "BLAST",
    chain: Chain.BLAST,
    name: "Blast Mainnet",
    kebabCaseId: "blast-mainnet",
    explorerUrl: "",
    networkChainId: 81457,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Blast,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BLAST_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.BLAST_MAINNET),
    currency: "BLAST",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.BLAST_SEPOLIA]: {
    id: "BLAST_SEPOLIA",
    network: Network.BLAST_SEPOLIA,
    chainId: "BLAST",
    chain: Chain.BLAST,
    name: "Blast Sepolia",
    kebabCaseId: "blast-sepolia",
    explorerUrl: "",
    networkChainId: 168587773, // TODO
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Blast,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BLAST_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BLAST_SEPOLIA),
    currency: "BLAST",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.SCROLL_MAINNET]: {
    id: "SCROLL_MAINNET",
    network: Network.SCROLL_MAINNET,
    chainId: "SCROLL",
    chain: Chain.SCROLL,
    name: "Scroll Mainnet",
    kebabCaseId: "scroll-mainnet",
    explorerUrl: "",
    networkChainId: 534352,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Scroll,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SCROLL_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.SCROLL_MAINNET),
    currency: "SCROLL",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.SCROLL_SEPOLIA]: {
    id: "SCROLL_SEPOLIA",
    network: Network.SCROLL_SEPOLIA,
    chainId: "SCROLL",
    chain: Chain.SCROLL,
    name: "Scroll Sepolia",
    kebabCaseId: "scroll-sepolia",
    explorerUrl: "",
    networkChainId: 534351,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Scroll,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SCROLL_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.SCROLL_SEPOLIA),
    currency: "SCROLL",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.LINEA_MAINNET]: {
    id: "LINEA_MAINNET",
    network: Network.LINEA_MAINNET,
    chainId: "LINEA",
    chain: Chain.LINEA,
    name: "Linea Mainnet",
    kebabCaseId: "linea-mainnet",
    explorerUrl: "https://lineascan.build",
    networkChainId: 59144,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Linea,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.LINEA_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.LINEA_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/linea-chain-api-quickstart",
  },
  [Network.LINEA_SEPOLIA]: {
    id: "LINEA_SEPOLIA",
    network: Network.LINEA_SEPOLIA,
    chainId: "LINEA",
    chain: Chain.LINEA,
    name: "Linea Sepolia",
    kebabCaseId: "linea-sepolia",
    explorerUrl: "https://sepolia.lineascan.build",
    networkChainId: 59141,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Linea,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.LINEA_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.LINEA_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "https://docs.alchemy.com/reference/linea-chain-api-quickstart",
  },
  [Network.GNOSIS_MAINNET]: {
    id: "GNOSIS_MAINNET",
    network: Network.GNOSIS_MAINNET,
    chainId: "GNOSIS",
    chain: Chain.GNOSIS,
    name: "Gnosis Mainnet",
    kebabCaseId: "gnosis-mainnet",
    explorerUrl: "",
    networkChainId: 100,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.Gnosis,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.GNOSIS_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.GNOSIS_MAINNET),
    currency: "xDAI",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.GNOSIS_CHIADO]: {
    id: "GNOSIS_CHIADO",
    network: Network.GNOSIS_CHIADO,
    chainId: "GNOSIS",
    chain: Chain.GNOSIS,
    name: "Gnosis Chiado",
    kebabCaseId: "gnosis-chiado",
    explorerUrl: "",
    networkChainId: 10200,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.Gnosis,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.GNOSIS_CHIADO,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.GNOSIS_CHIADO),
    currency: "xDAI",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.FANTOM_MAINNET]: {
    id: "FANTOM_MAINNET",
    network: Network.FANTOM_MAINNET,
    chainId: "FANTOM",
    chain: Chain.FANTOM,
    name: "Fantom Mainnet",
    kebabCaseId: "fantom-mainnet",
    explorerUrl: "",
    networkChainId: 250,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Fantom,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.FANTOM_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.FANTOM_MAINNET),
    currency: "FTM",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.FANTOM_TESTNET]: {
    id: "FANTOM_TESTNET",
    network: Network.FANTOM_TESTNET,
    chainId: "FANTOM",
    chain: Chain.FANTOM,
    name: "Fantom Testnet",
    kebabCaseId: "fantom-testnet",
    explorerUrl: "",
    networkChainId: 4002,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    supportedWebhookTypes: [],
    publicOverrideFlag: WaffleFlag.Fantom,
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.FANTOM_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.FANTOM_TESTNET),
    currency: "FTM",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MANTLE_MAINNET]: {
    id: "MANTLE_MAINNET",
    network: Network.MANTLE_MAINNET,
    chainId: "MANTLE",
    chain: Chain.MANTLE,
    name: "Mantle Mainnet",
    kebabCaseId: "mantle-mainnet",
    explorerUrl: "",
    networkChainId: 5000,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.MantleMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MANTLE_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.MANTLE_MAINNET),
    currency: "MNT",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.MANTLE_SEPOLIA]: {
    id: "MANTLE_SEPOLIA",
    network: Network.MANTLE_SEPOLIA,
    chainId: "MANTLE",
    chain: Chain.MANTLE,
    name: "Mantle Sepolia",
    kebabCaseId: "mantle-sepolia",
    explorerUrl: "",
    networkChainId: 5003,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.MantleSepolia,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MANTLE_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.MANTLE_SEPOLIA),
    currency: "MNT",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.CELO_MAINNET]: {
    id: "CELO_MAINNET",
    network: Network.CELO_MAINNET,
    chainId: "CELO",
    chain: Chain.CELO,
    name: "Celo Mainnet",
    kebabCaseId: "celo-mainnet",
    explorerUrl: "",
    networkChainId: 42220, // TODO
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Celo,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.CELO_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.CELO_MAINNET),
    currency: "CELO",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.CELO_ALFAJORES]: {
    id: "CELO_ALFAJORES",
    network: Network.CELO_ALFAJORES,
    chainId: "CELO",
    chain: Chain.CELO,
    name: "Celo Alfajores",
    kebabCaseId: "celo-alfajores",
    explorerUrl: "",
    networkChainId: 44787,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.Celo,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.CELO_ALFAJORES,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.CELO_ALFAJORES),
    currency: "CELO",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.BERACHAIN_ARTIO]: {
    id: "BERACHAIN_ARTIO",
    network: Network.BERACHAIN_ARTIO,
    chainId: "BERACHAIN",
    chain: Chain.BERACHAIN,
    name: "Berachain Artio",
    kebabCaseId: "berachain-artio",
    explorerUrl: "https://artio.beratrail.io/",
    networkChainId: 80085, // 0x138d5
    availability: Availability.PRERELEASE,
    isPaidOnly: false, // chain deal
    publicOverrideFlag: WaffleFlag.BerachainArtio,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BERACHAIN_ARTIO,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BERACHAIN_ARTIO),
    currency: "BERA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.BERACHAIN_BARTIO]: {
    id: "BERACHAIN_BARTIO",
    network: Network.BERACHAIN_BARTIO,
    chainId: "BERACHAIN",
    chain: Chain.BERACHAIN,
    name: "Berachain bArtio",
    kebabCaseId: "berachain-bartio",
    explorerUrl: "https://bartio.beratrail.io/",
    networkChainId: 80084, // 0x138d4
    availability: Availability.PRERELEASE,
    isPaidOnly: false, // chain deal
    publicOverrideFlag: WaffleFlag.BerachainBArtio,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BERACHAIN_BARTIO,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.BERACHAIN_BARTIO),
    currency: "BERA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.METIS_MAINNET]: {
    id: "METIS_MAINNET",
    network: Network.METIS_MAINNET,
    chainId: "METIS",
    chain: Chain.METIS,
    name: "Metis Mainnet",
    kebabCaseId: "metis-mainnet",
    explorerUrl: "",
    networkChainId: 1088,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.MetisMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.METIS_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.METIS_MAINNET),
    currency: "METIS",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.OPBNB_MAINNET]: {
    id: "OPBNB_MAINNET",
    network: Network.OPBNB_MAINNET,
    chainId: "OPBNB",
    chain: Chain.OPBNB,
    name: "opBNB Mainnet",
    kebabCaseId: "opbnb-mainnet",
    explorerUrl: "",
    networkChainId: 204,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.opBNB,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.OPBNB_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPBNB_MAINNET),
    currency: "opBNB",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.OPBNB_TESTNET]: {
    id: "OPBNB_TESTNET",
    network: Network.OPBNB_TESTNET,
    chainId: "OPBNB",
    chain: Chain.OPBNB,
    name: "opBNB Testnet",
    kebabCaseId: "opbnb-testnet",
    explorerUrl: "",
    networkChainId: 5611,
    availability: Availability.PUBLIC,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.opBNB,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.OPBNB_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.OPBNB_TESTNET),
    currency: "opBNB",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.POLYNOMIAL_MAINNET]: {
    id: "POLYNOMIAL_MAINNET",
    network: Network.POLYNOMIAL_MAINNET,
    chainId: "POLYNOMIAL",
    chain: Chain.POLYNOMIAL,
    name: "Polynomial Mainnet",
    kebabCaseId: "polynomial-mainnet",
    explorerUrl: "",
    networkChainId: 8008,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.PolynomialMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.POLYNOMIAL_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.POLYNOMIAL_MAINNET),
    currency: "", // TODO
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "",
  },
  [Network.POLYNOMIAL_SEPOLIA]: {
    id: "POLYNOMIAL_SEPOLIA",
    network: Network.POLYNOMIAL_SEPOLIA,
    chainId: "POLYNOMIAL",
    chain: Chain.POLYNOMIAL,
    name: "Polynomial Sepolia",
    kebabCaseId: "polynomial-sepolia",
    explorerUrl: "",
    networkChainId: 8009,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.PolynomialSepolia,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.POLYNOMIAL_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.POLYNOMIAL_SEPOLIA),
    currency: "", // TODO
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    supportsOnlyAccountAbstraction: true,
    docsUrl: "",
  },
  [Network.SHAPE_MAINNET]: {
    id: "SHAPE_MAINNET",
    network: Network.SHAPE_MAINNET,
    chainId: "SHAPE",
    chain: Chain.SHAPE,
    name: "Shape Mainnet",
    kebabCaseId: "shape-mainnet",
    explorerUrl: "",
    networkChainId: 360,
    availability: Availability.PRERELEASE,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ShapeMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.SHAPE_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.SHAPE_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.SHAPE_SEPOLIA]: {
    id: "SHAPE_SEPOLIA",
    network: Network.SHAPE_SEPOLIA,
    chainId: "SHAPE",
    chain: Chain.SHAPE,
    name: "Shape Sepolia",
    kebabCaseId: "shape-sepolia",
    explorerUrl: "",
    networkChainId: 11011,
    availability: Availability.PUBLIC,
    isPaidOnly: false,
    publicOverrideFlag: WaffleFlag.ShapeSepolia,
    supportedWebhookTypes: [
      WebhookType.ADDRESS_ACTIVITY,
      WebhookType.GRAPHQL,
      WebhookType.NFT_ACTIVITY,
    ],
    supportsGasPolicies: true,
    protoNetwork: ProtoNetwork.SHAPE_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.SHAPE_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: true,
    docsUrl: "",
  },
  [Network.BOB_MAINNET]: {
    id: "BOB_MAINNET",
    network: Network.BOB_MAINNET,
    chainId: "BOB",
    chain: Chain.BOB,
    name: "BOB Mainnet",
    kebabCaseId: "bob-mainnet",
    explorerUrl: "https://explorer.gobob.xyz/",
    networkChainId: 60808, // 0xed88
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.BOBMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.BOB_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.BOB_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.CROSSFI_TESTNET]: {
    id: "CROSSFI_TESTNET",
    network: Network.CROSSFI_TESTNET,
    chainId: "CROSSFI",
    chain: Chain.CROSSFI,
    name: "CrossFi Testnet",
    kebabCaseId: "crossfi-testnet",
    explorerUrl: "https://xfiscan.com/",
    networkChainId: 4157, // 0x103d
    availability: Availability.PRERELEASE,
    isPaidOnly: false, // chain deal
    publicOverrideFlag: WaffleFlag.CrossFiTestnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.CROSSFI_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.CROSSFI_TESTNET),
    currency: "XFI",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.IOTA_MAINNET]: {
    id: "IOTA_MAINNET",
    network: Network.IOTA_MAINNET,
    chainId: "IOTA",
    chain: Chain.IOTA,
    name: "Iota Mainnet",
    kebabCaseId: "iota-mainnet",
    explorerUrl: "https://explorer.evm.iota.org/",
    networkChainId: 8822, // 0x2276
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.IotaMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.IOTA_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.IOTA_MAINNET),
    currency: "IOTA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.IOTA_TESTNET]: {
    id: "IOTA_TESTNET",
    network: Network.IOTA_TESTNET,
    chainId: "IOTA",
    chain: Chain.IOTA,
    name: "Iota Testnet",
    kebabCaseId: "iota-testnet",
    explorerUrl: "https://explorer.evm.testnet.iotaledger.net/",
    networkChainId: 1075, // 0x433
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.IotaTestnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.IOTA_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.IOTA_TESTNET),
    currency: "IOTA",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MODE_MAINNET]: {
    id: "MODE_MAINNET",
    network: Network.MODE_MAINNET,
    chainId: "MODE",
    chain: Chain.MODE,
    name: "Mode Mainnet",
    kebabCaseId: "mode-mainnet",
    explorerUrl: "https://explorer.mode.network/",
    networkChainId: 34443, // 0x868b
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.ModeMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MODE_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.MODE_MAINNET),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MODE_SEPOLIA]: {
    id: "MODE_SEPOLIA",
    network: Network.MODE_SEPOLIA,
    chainId: "MODE",
    chain: Chain.MODE,
    name: "Mode Sepolia",
    kebabCaseId: "mode-sepolia",
    explorerUrl: "https://sepolia.explorer.mode.network/",
    networkChainId: 919, // 0x397
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.ModeSepolia,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MODE_SEPOLIA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.MODE_SEPOLIA),
    currency: "ETH",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MOONBASE_ALPHA]: {
    id: "MOONBASE_ALPHA",
    network: Network.MOONBASE_ALPHA,
    chainId: "MOONBASE",
    chain: Chain.MOONBASE,
    name: "Moonbase Alpha",
    kebabCaseId: "moonbase-alpha",
    explorerUrl: "https://moonbase.moonscan.io/",
    networkChainId: 1287, // 0x507
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.MoonbaseAlpha,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MOONBASE_ALPHA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.MOONBASE_ALPHA),
    currency: "DEV",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MOONBEAM_MAINNET]: {
    id: "MOONBEAM_MAINNET",
    network: Network.MOONBEAM_MAINNET,
    chainId: "MOONBEAM",
    chain: Chain.MOONBEAM,
    name: "Moonbeam Mainnet",
    kebabCaseId: "moonbeam-mainnet",
    explorerUrl: "https://moonscan.io/",
    networkChainId: 1284, // 0x504
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.MoonbeamMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MOONBEAM_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.MOONBEAM_MAINNET),
    currency: "GLMR",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.MOONRIVER_MAINNET]: {
    id: "MOONRIVER_MAINNET",
    network: Network.MOONRIVER_MAINNET,
    chainId: "MOONRIVER",
    chain: Chain.MOONRIVER,
    name: "Moonriver Mainnet",
    kebabCaseId: "moonriver-mainnet",
    explorerUrl: "https://moonriver.moonscan.io/",
    networkChainId: 1285, // 0x505
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.MoonriverMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.MOONRIVER_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.MOONRIVER_MAINNET),
    currency: "MOVR",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.PALM_MAINNET]: {
    id: "PALM_MAINNET",
    network: Network.PALM_MAINNET,
    chainId: "PALM",
    chain: Chain.PALM,
    name: "Palm Mainnet",
    kebabCaseId: "palm-mainnet",
    explorerUrl: "https://explorer.palm.io",
    networkChainId: 11297108109, // 0x2a15c308d
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.PalmMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.PALM_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.PALM_MAINNET),
    currency: "PALM",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.PALM_TESTNET]: {
    id: "PALM_TESTNET",
    network: Network.PALM_TESTNET,
    chainId: "PALM",
    chain: Chain.PALM,
    name: "Palm Testnet",
    kebabCaseId: "palm-testnet",
    explorerUrl: "https://explorer.palm-uat.xyz",
    networkChainId: 11297108099, // 0x2a15c3083
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.PalmTestnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.PALM_TESTNET,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.PALM_TESTNET),
    currency: "PALM",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.SHIDEN_MAINNET]: {
    id: "SHIDEN_MAINNET",
    network: Network.SHIDEN_MAINNET,
    chainId: "SHIDEN",
    chain: Chain.SHIDEN,
    name: "Shiden Mainnet",
    kebabCaseId: "shiden-mainnet",
    explorerUrl: "https://shiden.subscan.io",
    networkChainId: 336, // 0x150
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.ShidenMainnet,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SHIDEN_MAINNET,
    sdkEnum: undefined,
    isTestNet: false,
    order: NETWORK_SORT_ORDER.indexOf(Network.SHIDEN_MAINNET),
    currency: "SDN",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
  [Network.SHIDEN_SHIBUYA]: {
    id: "SHIDEN_SHIBUYA",
    network: Network.SHIDEN_SHIBUYA,
    chainId: "SHIDEN",
    chain: Chain.SHIDEN,
    name: "Shiden Shibuya",
    kebabCaseId: "shiden-shibuya",
    explorerUrl: "https://shibuya.subscan.io",
    networkChainId: 81, // 0x51
    availability: Availability.PRERELEASE,
    isPaidOnly: true,
    publicOverrideFlag: WaffleFlag.ShidenShibuya,
    supportedWebhookTypes: [],
    supportsGasPolicies: false,
    protoNetwork: ProtoNetwork.SHIDEN_SHIBUYA,
    sdkEnum: undefined,
    isTestNet: true,
    order: NETWORK_SORT_ORDER.indexOf(Network.SHIDEN_SHIBUYA),
    currency: "SBY",
    supportsMempool: false,
    supportsReinforcedTxns: false,
    supportsWebsockets: false,
    docsUrl: "",
  },
};

export const CHAINS = sortBy(Object.values(CHAIN_INFO), "order").map(
  (c) => c.chain,
);

export const NETWORKS = sortBy(Object.values(NETWORK_INFO), "order").map(
  (info) => info.network,
);

export const COMPOSER_API_KEY = "xZF7o-Vl3z94HOqwaQtrZP06swu4_E15";
export const TRACE_COMPOSER_API_KEY = "dAnEgTX45tYXUXfNwtnXGRpJ-YiDBL7d";

export enum SignupSource {
  TWITTER = 1,
  TEAMMATE = 2,
  SEARCH_ENGINE = 3,
  TUTORIAL = 4,
  OTHER = 999,
}

export enum OnDemandActionType {
  UNSPECIFIED = 0,
  ALERT = 1,
  STOPPAGE = 2,
}

export enum OnDemandThresholdUnit {
  UNSPECIFIED = 0,
  CU = 1,
  DOLLAR = 2,
}

export enum LocalStorageKeys {
  AppListSort = "appListSort",
  AppSortOrder = "appSortOrder",
}
