import { FieldLabel, Tooltip } from "@alch/ui";
import { HelpCircleContained } from "@alch/ui/icons/16";
import Markdown from "react-markdown";

import { ComposerParam } from "../lib/params";

interface ComposerParamLabelProps {
  param: ComposerParam;
  unwrap?: boolean;
}

const ComposerParamLabel = ({ param, unwrap }: ComposerParamLabelProps) => {
  if (!param.name) return null;

  const paramType = param.type === "tuple" ? "array" : param.type;

  const label = (
    <span className="flex items-center gap-1">
      <span>
        {param.name}{" "}
        {param.required ? <span className="text-red-600">*</span> : null}
      </span>
      <span className="text-paragraph-100-regular text-grayscale-500">
        {param.type === "array" &&
        param.items.type !== "oneOf" &&
        param.items.type !== "anyOf" &&
        param.items.type !== "tuple"
          ? `${paramType} of ${param.items.type}s`
          : paramType}
      </span>
      {param.description ? (
        <Tooltip
          content={<Markdown skipHtml>{param.description}</Markdown>}
          withArrow
          side="right"
          align="start"
        >
          <HelpCircleContained className="text-grayscale-400" />
        </Tooltip>
      ) : null}
    </span>
  );

  if (unwrap) return label;

  return <FieldLabel label={label} />;
};

export default ComposerParamLabel;
