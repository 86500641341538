import * as React from "react";
import { forwardRef, memo } from "react";
const SvgBell02 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M9 20.6303C9.79613 21.2333 10.8475 21.6 12 21.6C13.1525 21.6 14.2039 21.2333 15 20.6303M3.57109 17.5272C3.09677 17.5272 2.83186 16.8207 3.11877 16.4282C3.78453 15.5174 4.42712 14.1815 4.42712 12.5728L4.45458 10.2418C4.45458 5.91087 7.83278 2.39999 12 2.39999C16.2286 2.39999 19.6566 5.96259 19.6566 10.3573L19.6291 12.5728C19.6291 14.1925 20.2495 15.5357 20.8882 16.4469C21.164 16.8404 20.8984 17.5272 20.43 17.5272H3.57109Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$i = forwardRef(SvgBell02);
const Memo$i = memo(ForwardRef$i);
const SvgArrowLeft = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M10.8333 18L5 12M5 12L10.8333 6M5 12H19", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$h = forwardRef(SvgArrowLeft);
const Memo$h = memo(ForwardRef$h);
const SvgArrowRight = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M13.1667 6L19 12M19 12L13.1667 18M19 12L5 12", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$g = forwardRef(SvgArrowRight);
const Memo$g = memo(ForwardRef$g);
const SvgChevronDown = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M16.8 9.60002L12 14.4L7.19999 9.60002", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$f = forwardRef(SvgChevronDown);
const Memo$f = memo(ForwardRef$f);
const SvgChevronLeft = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M14.4 16.8L9.59998 12L14.4 7.20005", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$e = forwardRef(SvgChevronLeft);
const Memo$e = memo(ForwardRef$e);
const SvgChevronRight = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M9.60002 7.19995L14.4 12L9.60002 16.8", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$d = forwardRef(SvgChevronRight);
const Memo$d = memo(ForwardRef$d);
const SvgChevronUp = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M7.20001 14.4L12 9.59998L16.8 14.4", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$c = forwardRef(SvgChevronUp);
const Memo$c = memo(ForwardRef$c);
const SvgDocumentation = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M15.3985 1.125V5.20311C15.3985 5.95387 16.0072 6.56248 16.7579 6.56248H20.836M18.797 3.16406C18.192 2.62273 17.5642 1.9807 17.1678 1.56371C16.9041 1.28624 16.5397 1.125 16.1569 1.125H5.88263C4.38112 1.125 3.1639 2.34221 3.16389 3.84373L3.16379 20.1561C3.16378 21.6576 4.381 22.8749 5.88252 22.8749L18.1169 22.8749C19.6184 22.8749 20.8356 21.6577 20.8357 20.1562L20.836 5.7444C20.836 5.39681 20.7034 5.06268 20.4622 4.81244C20.0161 4.34971 19.2711 3.58831 18.797 3.16406Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$b = forwardRef(SvgDocumentation);
const Memo$b = memo(ForwardRef$b);
const SvgData02 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M17.7602 12C17.7602 13.0604 18.6198 13.92 19.6802 13.92C20.7406 13.92 21.6002 13.0604 21.6002 12C21.6002 10.9396 20.7406 10.08 19.6802 10.08C18.6198 10.08 17.7602 10.9396 17.7602 12ZM17.7602 12H12.6002M17.7602 4.32002C17.7602 5.38041 18.6198 6.24002 19.6802 6.24002C20.7406 6.24002 21.6002 5.38041 21.6002 4.32002C21.6002 3.25964 20.7406 2.40003 19.6802 2.40003C18.6198 2.40003 17.7602 3.25964 17.7602 4.32002ZM17.7602 4.32002H6.59975M17.7602 19.68C17.7602 20.7404 18.6198 21.6 19.6802 21.6C20.7406 21.6 21.6002 20.7404 21.6002 19.68C21.6002 18.6196 20.7406 17.76 19.6802 17.76C18.6198 17.76 17.7602 18.6196 17.7602 19.68ZM17.7602 19.68H12.2402C11.71 19.68 11.2802 19.2502 11.2802 18.72V5.28003M6.23975 4.32002C6.23975 5.38041 5.38014 6.24002 4.31975 6.24002C3.25936 6.24002 2.39975 5.38041 2.39975 4.32002C2.39975 3.25964 3.25936 2.40002 4.31975 2.40002C5.38014 2.40002 6.23975 3.25964 6.23975 4.32002Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$a = forwardRef(SvgData02);
const Memo$a = memo(ForwardRef$a);
const SvgCheck = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M16.8 8.3999L9.6405 15.5999L7.20001 13.1456", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$9 = forwardRef(SvgCheck);
const Memo$9 = memo(ForwardRef$9);
const SvgDash = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M19.2 12L4.79999 12", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$8 = forwardRef(SvgDash);
const Memo$8 = memo(ForwardRef$8);
const SvgMenu01 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M21.6 19.1998H2.40002M21.6 11.9998H2.40002M21.6 4.7998H2.40002", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$7 = forwardRef(SvgMenu01);
const Memo$7 = memo(ForwardRef$7);
const SvgPlus02 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M12 6L12 18M18 12L6 12", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$6 = forwardRef(SvgPlus02);
const Memo$6 = memo(ForwardRef$6);
const SvgSearch01 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M16.9268 17.0401L20.4 20.4001M19.28 11.4401C19.28 15.77 15.7699 19.2801 11.44 19.2801C7.11006 19.2801 3.59998 15.77 3.59998 11.4401C3.59998 7.11019 7.11006 3.6001 11.44 3.6001C15.7699 3.6001 19.28 7.11019 19.28 11.4401Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$5 = forwardRef(SvgSearch01);
const Memo$5 = memo(ForwardRef$5);
const SvgX03 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M17 7L7 17M17 17L7 7", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef$4 = forwardRef(SvgX03);
const Memo$4 = memo(ForwardRef$4);
const SvgXCircleContained = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M16.1011 9.3129C16.4916 8.92237 16.4916 8.28921 16.1011 7.89868C15.7106 7.50816 15.0774 7.50816 14.6869 7.89868L16.1011 9.3129ZM7.89868 14.6869C7.50816 15.0774 7.50816 15.7106 7.89868 16.1011C8.28921 16.4916 8.92237 16.4916 9.3129 16.1011L7.89868 14.6869ZM14.6869 16.1011C15.0774 16.4916 15.7106 16.4916 16.1011 16.1011C16.4916 15.7106 16.4916 15.0774 16.1011 14.6869L14.6869 16.1011ZM9.3129 7.89868C8.92237 7.50816 8.28921 7.50816 7.89868 7.89868C7.50816 8.28921 7.50816 8.92237 7.89868 9.3129L9.3129 7.89868ZM20.5999 11.9999C20.5999 16.7496 16.7496 20.5999 11.9999 20.5999V22.5999C17.8541 22.5999 22.5999 17.8541 22.5999 11.9999H20.5999ZM11.9999 20.5999C7.25025 20.5999 3.3999 16.7496 3.3999 11.9999H1.3999C1.3999 17.8541 6.14568 22.5999 11.9999 22.5999V20.5999ZM3.3999 11.9999C3.3999 7.25025 7.25025 3.3999 11.9999 3.3999V1.3999C6.14568 1.3999 1.3999 6.14568 1.3999 11.9999H3.3999ZM11.9999 3.3999C16.7496 3.3999 20.5999 7.25025 20.5999 11.9999H22.5999C22.5999 6.14568 17.8541 1.3999 11.9999 1.3999V3.3999ZM14.6869 7.89868L11.2928 11.2928L12.707 12.707L16.1011 9.3129L14.6869 7.89868ZM11.2928 11.2928L7.89868 14.6869L9.3129 16.1011L12.707 12.707L11.2928 11.2928ZM16.1011 14.6869L12.707 11.2928L11.2928 12.707L14.6869 16.1011L16.1011 14.6869ZM12.707 11.2928L9.3129 7.89868L7.89868 9.3129L11.2928 12.707L12.707 11.2928Z", fill: "currentColor" }));
const ForwardRef$3 = forwardRef(SvgXCircleContained);
const Memo$3 = memo(ForwardRef$3);
const SvgLayers02 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 25, height: 24, viewBox: "0 0 25 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M21.9332 19.4671C21.9332 20.6453 20.9911 21.6004 19.829 21.6004L4.83726 21.6004C3.67519 21.6004 2.73315 20.6453 2.73315 19.4671L2.73315 16.2671C2.73315 15.0888 3.6752 14.1337 4.83726 14.1337L19.829 14.1337C20.9911 14.1337 21.9332 15.0888 21.9332 16.2671V19.4671Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M21.9332 4.53372C21.9332 3.35552 20.9911 2.40039 19.829 2.40039L4.83726 2.40039C3.6752 2.40039 2.73315 3.35552 2.73315 4.53372L2.73315 7.73372C2.73315 8.91193 3.6752 9.86706 4.83726 9.86706H19.829C20.9911 9.86706 21.9332 8.91193 21.9332 7.73372V4.53372Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$2 = forwardRef(SvgLayers02);
const Memo$2 = memo(ForwardRef$2);
const SvgUserProfile01 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M20.3996 21.6L20.3999 18.0003C20.4001 16.012 18.7882 14.4 16.7999 14.4H7.20062C5.21256 14.4 3.60085 16.0115 3.60062 17.9996L3.60022 21.6M15.6002 5.99999C15.6002 7.98822 13.9884 9.59999 12.0002 9.59999C10.012 9.59999 8.40022 7.98822 8.40022 5.99999C8.40022 4.01177 10.012 2.39999 12.0002 2.39999C13.9884 2.39999 15.6002 4.01177 15.6002 5.99999Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
const ForwardRef$1 = forwardRef(SvgUserProfile01);
const Memo$1 = memo(ForwardRef$1);
const SvgLockOpen03 = (props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M6.375 7.88574C6.375 4.84683 8.78357 2.40002 11.775 2.40002C13.6384 2.40002 15.2757 3.34946 16.2455 4.80002M6.6 8.80002C5.61 8.80002 4.8 9.62288 4.8 10.6286V19.7715C4.8 20.7772 5.61 21.6 6.6 21.6H17.4C18.39 21.6 19.2 20.7772 19.2 19.7715V10.6286C19.2 9.62288 18.39 8.80002 17.4 8.80002H6.6Z", stroke: "currentColor", strokeWidth: 2, strokeLinecap: "round" }));
const ForwardRef = forwardRef(SvgLockOpen03);
const Memo = memo(ForwardRef);
export {
  Memo$h as ArrowLeft,
  Memo$g as ArrowRight,
  Memo$i as Bell02,
  Memo$9 as Check,
  Memo$f as ChevronDown,
  Memo$e as ChevronLeft,
  Memo$d as ChevronRight,
  Memo$c as ChevronUp,
  Memo$8 as Dash,
  Memo$a as Data02,
  Memo$b as Documentation,
  Memo$2 as Layers02,
  Memo as LockOpen03,
  Memo$7 as Menu01,
  Memo$6 as Plus02,
  Memo$5 as Search01,
  Memo$1 as UserProfile01,
  Memo$4 as X03,
  Memo$3 as XCircleContained
};
