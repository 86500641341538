import { z } from "zod";

enum InAppNotificationType {
  UsageAlert = "USAGE_ALERT",
  ErrorAlert = "ERROR_ALERT",
}

const InAppNotification = z.object({
  id: z.number(),
  userId: z.number(),
  teamId: z.number(),
  alertId: z.number().optional(),
  triggeredAt: z.string().datetime(),
  type: z.nativeEnum(InAppNotificationType),
  body: z.string(),
  read: z.boolean(),
});
type InAppNotification = z.infer<typeof InAppNotification>;

export { InAppNotification, InAppNotificationType };
