import { ChainInfo, NetworkInfo } from "./constants";

/**
 * Returns network name removing all parts of the chain name from the network name
 *
 * i.e. Chain: "Ethereum", Network: "Ethereum Mainnet" -> "Mainnet"
 *      Chain: "Polygon PoS", Network: "Polygon Mainnet" -> "Mainnet"
 */
export default function networkNameWithoutChain({
  chainInfo,
  networkInfo,
}: {
  chainInfo: Pick<ChainInfo, "name">;
  networkInfo: Pick<NetworkInfo, "name">;
}): string {
  const name = chainInfo.name
    .split(" ")
    .reduce((acc, part) => acc.replace(part, ""), networkInfo.name)
    .trim();

  return name;
}
