import NetworkEmblem from "@components/ui/icons/NetworkEmblem";
import { ChainInfo, Network, NetworkInfo } from "@util/constants.ts";
import networkNameWithoutChain from "@util/networkNameWithoutChain";
import React from "react";

export interface SelectedNetworksProps {
  values: Network | Network[];
  networkInfos: NetworkInfo[];
  chainInfo?: ChainInfo;
  placeholder?: React.ReactNode;
}

function SelectedNetworks({
  values,
  networkInfos,
  chainInfo,
  placeholder = "All networks",
}: SelectedNetworksProps) {
  const selectedNetworkInfos = networkInfos.filter((info) =>
    Array.isArray(values)
      ? values.includes(info.network)
      : values === info.network,
  );

  return (
    <div className="flex min-w-0 items-center gap-2">
      {networkInfos.length > 0 ? (
        <div className="flex flex-row-reverse pl-2">
          {selectedNetworkInfos.slice(0, 5).map((info) => {
            return (
              <div
                key={info.network}
                className="relative -ml-2 rounded-full border border-white bg-white"
              >
                <NetworkEmblem networkInfo={info} className="size-6" />
              </div>
            );
          })}
        </div>
      ) : null}

      <span className="min-w-0 truncate">
        {selectedNetworkInfos.length === 0
          ? placeholder
          : selectedNetworkInfos.length > 1
            ? `${selectedNetworkInfos.length} networks`
            : chainInfo
              ? networkNameWithoutChain({
                  chainInfo,
                  networkInfo: selectedNetworkInfos[0],
                })
              : selectedNetworkInfos[0].name}
      </span>
    </div>
  );
}

export default SelectedNetworks;
