import { FieldInfo } from "@alch/ui";
import { useController } from "react-hook-form";

import { type ComposerParamComponentProps } from ".";
import { SimpleToggleGroup } from "../../../components/ui/index";
import ComposerParamLabel from "./ComposerParamLabel";

const ComposerBooleanParam = ({
  control,
  name,
  param,
}: ComposerParamComponentProps<"boolean">) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <div>
      <ComposerParamLabel param={param} />

      <SimpleToggleGroup
        className="mt-2"
        type="single"
        value={String(field.value)}
        onValueChange={(value) =>
          field.onChange(!value && value !== "false" ? null : JSON.parse(value))
        }
        items={[
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ]}
      />

      <FieldInfo
        errorMessage={fieldState.error?.message}
        description={undefined}
      />
    </div>
  );
};

export default ComposerBooleanParam;
