import usePaywalledNetworks from "@hooks/usePaywalledNetworks";
import { Network, NetworkInfo } from "@util/constants.ts";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import NetworkItem from "./NetworkItem";

export enum NetworkSort {
  Name = "name",
  Order = "order",
}

function useNetworkItems({
  networkInfos,
  disabledNetworks = [],
  sort,
}: {
  networkInfos: NetworkInfo[];
  disabledNetworks?: Network[];
  sort?: NetworkSort;
}) {
  const paywalledNetworks = usePaywalledNetworks();

  return useMemo(
    () =>
      sortBy(
        Object.values(networkInfos),
        (n) => {
          // Move disabled networks to the end
          return disabledNetworks.includes(n.network) ? 1 : 0;
        },
        (n) => {
          switch (sort) {
            case NetworkSort.Name:
              return n.name;
            case NetworkSort.Order:
            default:
              // If order is -1 (not found), put it at the end
              return n.order === -1 ? Number.MAX_SAFE_INTEGER : n.order;
          }
        },
      ).map((networkInfo) => {
        const disabled = disabledNetworks.includes(networkInfo.network);
        const paywalled = paywalledNetworks.data?.includes(networkInfo.network);

        return {
          disabled: disabled || paywalled,
          value: networkInfo.network,
          label: networkInfo.name,
          network: networkInfo.network,
          displayLabel: (
            <NetworkItem
              networkInfo={networkInfo}
              disabled={disabled}
              paywalled={paywalled}
            />
          ),
        };
      }),
    [networkInfos, disabledNetworks, paywalledNetworks.data, sort],
  );
}

export { useNetworkItems };
