import { z } from "zod";

export enum TeamType {
  Personal = "personal",
  Team = "team",
}

export enum TeamSize {
  One = "one",
  TwoToTen = "twoToTen",
  ElevenToFifty = "elevenToFifty",
  FiftyOnePlus = "fiftyOnePlus",
}

export enum ProjectType {
  Analytics = "analytics",
  Defi = "defi",
  Gaming = "gaming",
  Wallet = "wallet",
  Security = "security",
  Social = "social",
  Marketplace = "marketplace",
  InfraAndTooling = "infraAndTooling",
  NFTs = "nfts",
  Identity = "identity",
}

export const SignupOptions = z.object({
  project_type: z.union([z.nativeEnum(ProjectType), z.string()]),
  team_type: z.nativeEnum(TeamType),
  team_size: z.nativeEnum(TeamSize).nullable(),
  chains: z.string(),
  other_chains: z.string().nullable(),
  enterprise_project_details: z.string().nullable(),
});

export type SignupOptions = z.infer<typeof SignupOptions>;

export enum SetupStage {
  COMPLETE = 0,
  CREATED = 1,
  QUERYING = 2,
  CELEBRATING = 3,
  INVITING_TEAM = 4,
  BILLING = 5,
}
