import useActivePlan from "@features/profile/PlanPage/hooks/useActivePlan";
import { WaffleFlag, useWaffleFlags } from "@queries/useWaffleFlags";
import { useMemo } from "react";
import { NETWORK_INFO, Network, SubscriptionTier } from "../util/constants";

const NETWORK_PAYWALL_FLAGS: Map<Network, WaffleFlag | undefined> = new Map(
  Object.entries(NETWORK_INFO)
    .filter(([, info]) => info.isPaidOnly)
    .map(([network, info]) => [
      Number(network) as Network,
      info.publicOverrideFlag,
    ]),
);

const flags = Array.from(NETWORK_PAYWALL_FLAGS.values()).filter(
  (v): v is WaffleFlag => Boolean(v),
);
const networks = Array.from(NETWORK_PAYWALL_FLAGS.keys());
const noPaywall: Network[] = [];

export default function usePaywalledNetworks() {
  const networkPaywallFlags = useWaffleFlags(flags);

  const paywalledNetworks = useMemo(
    () =>
      networks.filter((network) => {
        const flag = NETWORK_PAYWALL_FLAGS.get(network);
        return flag && !networkPaywallFlags[flag];
      }),
    [networkPaywallFlags],
  );

  return useActivePlan({
    select: (plan) => {
      if (plan.tier !== SubscriptionTier.FREE) {
        return noPaywall;
      }

      return paywalledNetworks;
    },
  });
}
