import { pick } from "lodash";
import { parse, stringify } from "qs";

import { NetworkInfo } from "@util/constants";

import { composerFormSchemaForMethod, ComposerFormState } from "./form";
import { ComposerChainMethods } from "./methods";

const serializableProperties = ["network", "method", "query", "body"] as const;

export type SerializableComposerFormState = Pick<
  ComposerFormState,
  (typeof serializableProperties)[number]
>;

export const nullifyEmptyValues = (value: unknown): unknown => {
  if (Array.isArray(value)) {
    const arr = value.map(nullifyEmptyValues).filter((val) => val != null);

    return arr.length === 0 ? undefined : arr;
  }

  if (typeof value === "object" && value != null) {
    const objEntries = Object.entries(value)
      .map(([key, value]) => [key, nullifyEmptyValues(value)])
      .filter((entry) => entry[1] != null);

    return objEntries.length === 0 ? undefined : Object.fromEntries(objEntries);
  }

  return value === "" || value == null ? undefined : value;
};

export const buildShareUrl = ({
  baseUrl,
  state,
  networkInfos,
}: {
  baseUrl: string;
  state: SerializableComposerFormState;
  networkInfos: NetworkInfo[];
}): string => {
  const networkInfo = networkInfos.find(
    (info) => info.network === state.network,
  );

  const normalizedState = pick(
    { ...state, network: networkInfo?.id },
    serializableProperties,
  );

  return `${baseUrl}/?${stringify(normalizedState, {
    strictNullHandling: true,
    allowDots: true,
  })}`;
};

const booleanParser = (str: string, defaultDecoder: (str: string) => string) =>
  str === "true" ? true : str === "false" ? false : defaultDecoder(str);

export const parseStateFromShareUrl = ({
  methods,
  search,
  networkInfos,
}: {
  methods: ComposerChainMethods;
  search: string;
  networkInfos: NetworkInfo[];
}): SerializableComposerFormState | null => {
  const state = parse(search, {
    allowDots: true,
    ignoreQueryPrefix: true,
    depth: 10,
    strictNullHandling: true,
    decoder: booleanParser,
  });
  const networkInfo =
    state.network == null
      ? null
      : networkInfos.find((info) => info.id === state.network);

  if (networkInfo == null) return null;

  const chain = networkInfo.chain;
  const methodName = String(state.method);
  const method = methods[networkInfo.chain]?.[methodName];

  if (method == null) return null;

  const schema = composerFormSchemaForMethod(method, {
    strict: false,
  }).partial();

  const parseResult = schema.safeParse({
    ...state,
    network: networkInfo.network,
    chain,
  });

  if (!parseResult.success) return null;

  return parseResult.data as SerializableComposerFormState;
};
