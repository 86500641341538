import { Button, Tooltip } from "@alch/ui";
import { AlertTriangle, Lock03 } from "@alch/ui/icons/16";
import NetworkEmblem from "@components/ui/icons/NetworkEmblem";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import { NetworkInfo } from "@util/constants";
import { Path, pathBuilder } from "@util/paths";
import clsx from "clsx";

interface NetworkItemProps {
  networkInfo: NetworkInfo;
  appId?: string;
  disabled?: boolean;
  paywalled?: boolean;
}

const NetworkItem = ({
  networkInfo,
  appId,
  disabled = false,
  paywalled,
}: NetworkItemProps) => {
  const showDisabledIcon = !!appId;
  const disabledNetworkTooltip = appId ? (
    <div className="text-center text-paragraph-100-regular">
      This network is disabled for this app.
      <span>
        {" "}
        You can change the permission in{" "}
        <ViewAsPreservingLink
          to={pathBuilder.appDetails(appId, "networks")}
          className="underline hover:no-underline"
          onClick={(e) => e.stopPropagation()}
        >
          Networks
        </ViewAsPreservingLink>
      </span>
    </div>
  ) : (
    "Not available for this tool"
  );

  return (
    <div className="flex items-center gap-2">
      <Tooltip
        content={disabledNetworkTooltip}
        side="right"
        withArrow
        disabled={!disabled && !showDisabledIcon}
      >
        <div
          className={clsx(
            "flex items-center gap-2",
            disabled || paywalled ? "opacity-50" : undefined,
          )}
        >
          <NetworkEmblem networkInfo={networkInfo} className="size-5" />
          <span className="text-black">{networkInfo.name}</span>
        </div>
      </Tooltip>

      {disabled && showDisabledIcon && (
        <Tooltip content={disabledNetworkTooltip}>
          <AlertTriangle className="text-red-600" />
        </Tooltip>
      )}

      {paywalled && (
        <Button
          as={ViewAsPreservingLink}
          to={Path.Billing}
          icon={<Lock03 />}
          intent="tertiary"
          size="sm"
          className="-my-2 ml-auto gap-1 bg-transparent text-label-100"
        >
          Upgrade
        </Button>
      )}
    </div>
  );
};

export default NetworkItem;
