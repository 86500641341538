import z from "zod";

export enum Chain {
  ETH = "eth",
  ARB = "arb",
  MATIC = "matic",
  OPT = "opt",
  SOLANA = "solana",
  ASTAR = "astar",
  POLYGONZKEVM = "polygonzkevm",
  STARKNET = "starknet",
  BASE = "base",
  ZKSYNC = "zksync",
  ZORA = "zora",
  FRAX = "frax",
  BNB = "bnb",
  AVAX = "avax",
  ARBNOVA = "arbnova",
  ALCHEMY = "alchemy",
  WORLDCHAIN = "worldchain",
  ZETACHAIN = "zetachain",
  BLAST = "blast",
  SCROLL = "scroll",
  LINEA = "linea",
  GNOSIS = "gnosis",
  FANTOM = "fantom",
  MANTLE = "mantle",
  CELO = "celo",
  BERACHAIN = "berachain",
  METIS = "metis",
  OPBNB = "opbnb",
  POLYNOMIAL = "polynomial",
  SHAPE = "shape",
  BOB = "bob",
  CROSSFI = "crossfi",
  IOTA = "iota",
  MODE = "mode",
  MOONBASE = "moonbase",
  MOONBEAM = "moonbeam",
  MOONRIVER = "moonriver",
  PALM = "palm",
  SHIDEN = "shiden",
}

/**
 * These enum values (currently) match the NaaS DB values, though that may not
 * be guaranteed in the future.
 *
 * The enum values do *not* match the protobuf values ({@link https://github.com/OMGWINNING/protobufs/blob/master/src/main/proto/common.proto#L57 explanation}).
 *
 * But the keys MUST match the protobuf keys in order for us to move towards compatibility with dynamic config.
 */
export enum Network {
  ETH_MAINNET = 0,
  ETH_ROPSTEN = 1,
  ETH_RINKEBY = 2,
  ETH_KOVAN = 3,
  ETH_GOERLI = 4,
  ETH_SEPOLIA = 5,
  ETH_HOLESKY = 6,
  ARB_MAINNET = 201,
  ARB_RINKEBY = 203,
  ARB_GOERLI = 205,
  ARB_SEPOLIA = 206,
  MATIC_MAINNET = 401,
  MATIC_MUMBAI = 406,
  MATIC_AMOY = 407,
  OPT_MAINNET = 501,
  OPT_KOVAN = 504,
  OPT_GOERLI = 505,
  OPT_SEPOLIA = 506,
  SOLANA_MAINNET = 701,
  SOLANA_DEVNET = 703,
  ASTAR_MAINNET = 801,
  POLYGONZKEVM_MAINNET = 901,
  POLYGONZKEVM_TESTNET = 902,
  POLYGONZKEVM_CARDONA = 903,
  STARKNET_MAINNET = 1001,
  STARKNET_GOERLI = 1002,
  STARKNET_SEPOLIA = 1003,
  BASE_MAINNET = 1101,
  BASE_GOERLI = 1102,
  BASE_SEPOLIA = 1103,
  ZKSYNC_MAINNET = 1201,
  ZKSYNC_SEPOLIA = 1202,
  ZORA_MAINNET = 1301,
  ZORA_SEPOLIA = 1302,
  FRAX_MAINNET = 1401,
  FRAX_SEPOLIA = 1402,
  BNB_MAINNET = 1501,
  BNB_TESTNET = 1502,
  AVAX_MAINNET = 1601,
  AVAX_FUJI = 1602,
  ARBNOVA_MAINNET = 1701,
  ALCHEMY_MAINNET = 1801,
  ALCHEMY_SEPOLIA = 1802,
  ALCHEMY_INTERNAL = 1803,
  WORLDCHAIN_MAINNET = 1901,
  WORLDCHAIN_SEPOLIA = 1902,
  ZETACHAIN_MAINNET = 2001,
  ZETACHAIN_TESTNET = 2002,
  BLAST_MAINNET = 2101,
  BLAST_SEPOLIA = 2102,
  SCROLL_MAINNET = 2201,
  SCROLL_SEPOLIA = 2202,
  LINEA_MAINNET = 2301,
  LINEA_SEPOLIA = 2302,
  GNOSIS_MAINNET = 2401,
  GNOSIS_CHIADO = 2402,
  FANTOM_MAINNET = 2501,
  FANTOM_TESTNET = 2502,
  MANTLE_MAINNET = 2601,
  MANTLE_SEPOLIA = 2602,
  CELO_MAINNET = 2701,
  CELO_ALFAJORES = 2702,
  BERACHAIN_ARTIO = 2801,
  BERACHAIN_BARTIO = 2802,
  METIS_MAINNET = 2901,
  OPBNB_MAINNET = 3001,
  OPBNB_TESTNET = 3002,
  POLYNOMIAL_MAINNET = 3101,
  POLYNOMIAL_SEPOLIA = 3102,
  SHAPE_MAINNET = 3201,
  SHAPE_SEPOLIA = 3202,
  BOB_MAINNET = 3301,
  CROSSFI_TESTNET = 3401,
  IOTA_MAINNET = 3501,
  IOTA_TESTNET = 3502,
  MODE_MAINNET = 3601,
  MODE_SEPOLIA = 3602,
  MOONBASE_ALPHA = 3701,
  MOONBEAM_MAINNET = 3801,
  MOONRIVER_MAINNET = 3901,
  PALM_MAINNET = 4001,
  PALM_TESTNET = 4002,
  SHIDEN_MAINNET = 4101,
  SHIDEN_SHIBUYA = 4102,
}
export const NetworkSchema = z.nativeEnum(Network);
