import { useMutation } from "@tanstack/react-query";
import { stringify } from "qs";

import {
  COMPOSER_API_KEY,
  NetworkInfo,
  TRACE_COMPOSER_API_KEY,
} from "@util/constants";
import { useEffectOnUpdate } from "@util/hooks";

import { ComposerFormState } from "./form";
import { ComposerMethod } from "./methods";
import { nullifyEmptyValues } from "./sharing";

interface UseMakeComposerRequestProps {
  method?: ComposerMethod;
  networkInfo: NetworkInfo;
}

const useMakeComposerRequest = ({
  method,
  networkInfo,
}: UseMakeComposerRequestProps) => {
  const mutation = useMutation<string, Error, ComposerFormState>({
    mutationFn: async (state) => {
      const isTraceMethod = state.method.includes("trace");
      const apiKey = isTraceMethod ? TRACE_COMPOSER_API_KEY : COMPOSER_API_KEY;

      if (!method) throw Error("Incomplete request");

      const requestUrl = method?.url
        .replace("{network}", networkInfo.kebabCaseId)
        .replace("{apiKey}", apiKey);

      const stringifiedQuery = stringify(nullifyEmptyValues(state.query), {
        addQueryPrefix: true,
        skipNulls: true,
        arrayFormat: "brackets",
      });
      const stringifiedBody = method.requestBody
        ? JSON.stringify(nullifyEmptyValues(state.body))
        : undefined;

      const response = await fetch(`${requestUrl}${stringifiedQuery}`, {
        method: method.method,
        body: stringifiedBody,
        headers: method.requestBody
          ? { "content-type": "application/json" }
          : undefined,
      });

      const body = await response.text();

      return body;
    },
    retry: false,
  });

  useEffectOnUpdate(() => {
    mutation.reset();
  }, [method]);

  return mutation;
};

export default useMakeComposerRequest;
