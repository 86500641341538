import { Chain, Network, NetworkInfo } from "@util/constants";
import { mapValues } from "lodash";

import type { ComposerChainMethods, ComposerMethod } from "./methods";

export type RawSchema = Record<
  Chain,
  Record<string, Omit<ComposerMethod, "networks"> & { networks: string[] }>
>;

const parseNetwork = ({
  chain,
  schemaNetwork,
  networkInfos,
}: {
  chain: Chain;
  schemaNetwork: string;
  networkInfos: NetworkInfo[];
}): Network | undefined => {
  const networkSubdomain = `${chain === Chain.MATIC ? "polygon" : chain}-${
    chain === Chain.BASE && schemaNetwork === "testnet"
      ? "goerli"
      : schemaNetwork
  }`;

  return networkInfos.find((info) => info.kebabCaseId === networkSubdomain)
    ?.network;
};

export const parseSchema = ({
  rawSchema,
  networkInfos,
}: {
  rawSchema: RawSchema;
  networkInfos: NetworkInfo[];
}): ComposerChainMethods => {
  return mapValues(rawSchema, (schemaChain, chain) => {
    return mapValues(schemaChain, (schemaMethod) => {
      return {
        ...schemaMethod,
        networks: schemaMethod.networks
          .map((network) =>
            parseNetwork({
              chain: chain as Chain,
              schemaNetwork: network,
              networkInfos,
            }),
          )
          .filter((network): network is Network => network != null),
      };
    });
  });
};
