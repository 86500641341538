import { ProjectType } from "./signup";

export enum Product {
  NodeApi = "node-api",
  NftApi = "nft-api",
  TokenApi = "token-api",
  TransfersApi = "transfers-api",
  BundlerApi = "bundler-api",
  DebugApi = "debug-api",
  EmbeddedAccounts = "embedded-accounts",
  GasManagerApi = "gas-manager-api",
  TraceApi = "trace-api",
  PrivateTransactionsApi = "private-transactions-api",
  Subgraphs = "subgraphs",
  TransactionSimulationApi = "transaction-simulation-api",
  TransactionReceiptsApi = "transaction-receipts-api",
  UserOperationsApi = "user-operations-api",
  Websockets = "smart-websockets",
  Webhooks = "webhooks",
}

export const recommendedProductsByProjectType: Record<ProjectType, Product[]> =
  {
    [ProjectType.Analytics]: [
      Product.Subgraphs,
      Product.Webhooks,
      Product.TransfersApi,
    ],
    [ProjectType.Defi]: [Product.Subgraphs, Product.TokenApi, Product.Webhooks],
    [ProjectType.Gaming]: [
      Product.Subgraphs,
      Product.Webhooks,
      Product.Websockets,
    ],
    [ProjectType.Wallet]: [
      Product.EmbeddedAccounts,
      Product.GasManagerApi,
      Product.TokenApi,
    ],
    [ProjectType.Security]: [
      Product.TransactionSimulationApi,
      Product.Webhooks,
      Product.TransfersApi,
    ],
    [ProjectType.Marketplace]: [
      Product.NftApi,
      Product.Webhooks,
      Product.TokenApi,
    ],
    [ProjectType.Social]: [Product.NftApi, Product.Webhooks, Product.TokenApi],
    [ProjectType.InfraAndTooling]: [
      Product.Webhooks,
      Product.Subgraphs,
      Product.TransfersApi,
    ],
    [ProjectType.NFTs]: [Product.NftApi, Product.Webhooks, Product.TokenApi],
    [ProjectType.Identity]: [
      Product.TokenApi,
      Product.Webhooks,
      Product.TransfersApi,
    ],
  };

export const otherProjectRecommendedProducts: Product[] = [
  Product.TokenApi,
  Product.NftApi,
  Product.Webhooks,
];
