import { CHAIN_INFO, Chain, NETWORK_INFO, Network } from "./constants";

/**
 * Returns a sorted, unique list of chains based on the networks passed in..
 */
export default function networksToChains(networks: Network[]): Chain[] {
  const chains = [
    ...new Set(networks.map((network) => NETWORK_INFO[network].chain)),
  ];
  return chains.sort((n1, n2) => CHAIN_INFO[n1].order - CHAIN_INFO[n2].order);
}
