import { FieldLabel } from "@alch/ui";
import { useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";

import ChainSelect from "@features/chain-select/ChainSelect";
import NetworkSelect from "@features/network-select/NetworkSelect";
import { analyticsEvent, EventPrefix } from "@util/analytics";
import { SelectField } from "../../components/ui/index";

import { ChainInfo, NetworkInfo } from "@util/constants";
import ComposerCategories from "./ComposerCategories";
import { methodsForCategories } from "./lib/categories";
import { ComposerFormState, useComposerNetworkSelect } from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";

interface ComposerMethodProps extends React.HTMLAttributes<HTMLDivElement> {
  control: Control<ComposerFormState>;
  methods: ComposerChainMethods;
  chainInfos: ChainInfo[];
  networkInfos: NetworkInfo[];
}

const ComposerMethod = ({
  control,
  methods,
  chainInfos,
  networkInfos,
  ...props
}: ComposerMethodProps) => {
  const [methodSearchQuery, setMethodSearchQuery] = useState("");

  const selectedChain = useWatch({ control, name: "chain" });
  const selectedMethodName = useWatch({ control, name: "method" });
  const selectedCategories = useWatch({ control, name: "categories" });

  const { composerNetworkInfos, composerChainInfos } = useComposerNetworkSelect(
    { methods, chainInfos, networkInfos },
  );

  const categoryMethods = methodsForCategories(
    methods[selectedChain],
    selectedCategories,
  );
  const selectedMethod = categoryMethods[selectedMethodName];

  const isSearchingMethods = !!methodSearchQuery;

  useEffect(() => {
    if (!isSearchingMethods) return;

    analyticsEvent(`${EventPrefix.Sandbox}: Searched for method`);
  }, [isSearchingMethods]);

  return (
    <div {...props}>
      <div className="px-4 pb-4">
        <div className="py-7 text-heading-h3">Select method</div>

        <FieldLabel className="mb-3" label="Chain and network" />

        <ChainSelect
          className="mb-3"
          control={control}
          name="chain"
          chainInfos={composerChainInfos}
          placeholder="Select chain"
        />

        <NetworkSelect
          control={control}
          networkInfos={composerNetworkInfos.filter(
            (n) => n.chain === selectedChain,
          )}
          name="network"
          placeholder="Select network"
        />

        <ComposerCategories control={control} methods={methods} />
      </div>

      <div className="bg-blue-50 px-4 py-6">
        <FieldLabel className="mb-3" label="Method" />

        <SelectField
          control={control}
          name="method"
          onSearch={setMethodSearchQuery}
          placeholder="Select method"
          popoverMinWidth={220}
          items={Object.keys(categoryMethods).map((methodName) => ({
            label: methodName,
            value: methodName,
          }))}
          description={selectedMethod?.description}
        />
      </div>
    </div>
  );
};

export default ComposerMethod;
