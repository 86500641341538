import { QueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import { createStore } from "redux";

import { initAnalytics } from "@util/analytics";
import { queryClient } from "../../http/query";
import Composer from "../composer";

import ErrorBoundary from "../../components/app/ErrorBoundary";
import ComposerStandaloneLayout from "./ComposerStandaloneLayout";

import "../../index.css";

initAnalytics();

const baseUrl = window.location.origin;

const reduxStore = createStore(() => null);

const ComposerStandaloneRoot = () => {
  const history = createBrowserHistory();

  return (
    <ReduxProvider store={reduxStore}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <ComposerStandaloneLayout>
            <ErrorBoundary>
              <Composer baseUrl={baseUrl} />
            </ErrorBoundary>
          </ComposerStandaloneLayout>
        </QueryClientProvider>
      </Router>
    </ReduxProvider>
  );
};

export default ComposerStandaloneRoot;
