import { Network } from "@alch/dx-webapp-api/shared";
import { mapValues } from "lodash";

import {
  ArbMainnetEmblem,
  ArbNovaMainnetEmblem,
  ArbTestnetEmblem,
  AstarMainnetEmblem,
  AvaxMainnetEmblem,
  AvaxTestnetEmblem,
  BaseMainnetEmblem,
  BaseTestnetEmblem,
  BerachainTestnetEmblem,
  BlastMainnetEmblem,
  BlastTestnetEmblem,
  BnbMainnetEmblem,
  BnbTestnetEmblem,
  BobMainnetEmblem,
  CeloMainnetEmblem,
  CeloTestnetEmblem,
  CrossFiTestnetEmblem,
  EthMainnetEmblem,
  EthTestnetEmblem,
  FantomMainnetEmblem,
  FantomTestnetEmblem,
  FraxMainnetEmblem,
  FraxTestnetEmblem,
  GnosisMainnetEmblem,
  GnosisTestnetEmblem,
  IotaMainnetEmblem,
  IotaTestnetEmblem,
  LineaMainnetEmblem,
  LineaTestnetEmblem,
  MantleMainnetEmblem,
  MantleTestnetEmblem,
  MetisMainnetEmblem,
  ModeMainnetEmblem,
  ModeTestnetEmblem,
  MoonbaseTestnetEmblem,
  MoonbeamMainnetEmblem,
  MoonriverMainnetEmblem,
  OptMainnetEmblem,
  OptTestnetEmblem,
  PalmMainnetEmblem,
  PalmTestnetEmblem,
  PlaceholderMainnetEmblem,
  PlaceholderTestnetEmblem,
  PolygonMainnetEmblem,
  PolygonTestnetEmblem,
  PolygonZkEvmMainnetEmblem,
  PolygonZkEvmTestnetEmblem,
  PolynomialMainnetEmblem,
  PolynomialTestnetEmblem,
  ScrollMainnetEmblem,
  ScrollTestnetEmblem,
  ShapeMainnetEmblem,
  ShapeTestnetEmblem,
  ShidenMainnetEmblem,
  ShidenTestnetEmblem,
  SolanaMainnetEmblem,
  SolanaTestnetEmblem,
  StarknetMainnetEmblem,
  StarknetTestnetEmblem,
  WorldChainMainnetEmblem,
  WorldChainTestnetEmblem,
  ZetachainMainnetEmblem,
  ZetachainTestnetEmblem,
  ZkSyncMainnetEmblem,
  ZkSyncTestnetEmblem,
  ZoraMainnetEmblem,
  ZoraTestnetEmblem,
} from "@alch/ui/assets/networks";

import { NETWORK_INFO } from "@util/constants";
import { createEmblemComponent } from "./emblem";

/**
 * Round network logo for each network.
 *
 * This is in a separate file from constants.ts to improve tree shaking.
 */
export const networkEmblemUrls: Record<Network, string> = {
  [Network.ETH_MAINNET]: EthMainnetEmblem,
  [Network.ETH_ROPSTEN]: EthTestnetEmblem,
  [Network.ETH_RINKEBY]: EthTestnetEmblem,
  [Network.ETH_KOVAN]: EthTestnetEmblem,
  [Network.ETH_GOERLI]: EthTestnetEmblem,
  [Network.ETH_SEPOLIA]: EthTestnetEmblem,
  [Network.ETH_HOLESKY]: EthTestnetEmblem,
  [Network.ARB_MAINNET]: ArbMainnetEmblem,
  [Network.ARB_RINKEBY]: ArbTestnetEmblem,
  [Network.ARB_GOERLI]: ArbTestnetEmblem,
  [Network.ARB_SEPOLIA]: ArbTestnetEmblem,
  [Network.MATIC_MAINNET]: PolygonMainnetEmblem,
  [Network.MATIC_MUMBAI]: PolygonTestnetEmblem,
  [Network.MATIC_AMOY]: PolygonTestnetEmblem,
  [Network.OPT_MAINNET]: OptMainnetEmblem,
  [Network.OPT_KOVAN]: OptTestnetEmblem,
  [Network.OPT_GOERLI]: OptTestnetEmblem,
  [Network.OPT_SEPOLIA]: OptTestnetEmblem,
  [Network.SOLANA_MAINNET]: SolanaMainnetEmblem,
  [Network.SOLANA_DEVNET]: SolanaTestnetEmblem,
  [Network.ASTAR_MAINNET]: AstarMainnetEmblem,
  [Network.POLYGONZKEVM_MAINNET]: PolygonZkEvmMainnetEmblem,
  [Network.POLYGONZKEVM_TESTNET]: PolygonZkEvmTestnetEmblem,
  [Network.POLYGONZKEVM_CARDONA]: PolygonZkEvmTestnetEmblem,
  [Network.STARKNET_MAINNET]: StarknetMainnetEmblem,
  [Network.STARKNET_GOERLI]: StarknetTestnetEmblem,
  [Network.STARKNET_SEPOLIA]: StarknetTestnetEmblem,
  [Network.BASE_MAINNET]: BaseMainnetEmblem,
  [Network.BASE_GOERLI]: BaseTestnetEmblem,
  [Network.BASE_SEPOLIA]: BaseTestnetEmblem,
  [Network.ZKSYNC_MAINNET]: ZkSyncMainnetEmblem,
  [Network.ZKSYNC_SEPOLIA]: ZkSyncTestnetEmblem,
  [Network.ZORA_MAINNET]: ZoraMainnetEmblem,
  [Network.ZORA_SEPOLIA]: ZoraTestnetEmblem,
  [Network.FRAX_MAINNET]: FraxMainnetEmblem,
  [Network.FRAX_SEPOLIA]: FraxTestnetEmblem,
  [Network.BNB_MAINNET]: BnbMainnetEmblem,
  [Network.BNB_TESTNET]: BnbTestnetEmblem,
  [Network.AVAX_MAINNET]: AvaxMainnetEmblem,
  [Network.AVAX_FUJI]: AvaxTestnetEmblem,
  [Network.ARBNOVA_MAINNET]: ArbNovaMainnetEmblem,
  [Network.ALCHEMY_MAINNET]: PlaceholderMainnetEmblem,
  [Network.ALCHEMY_SEPOLIA]: PlaceholderTestnetEmblem,
  [Network.ALCHEMY_INTERNAL]: PlaceholderTestnetEmblem,
  [Network.WORLDCHAIN_MAINNET]: WorldChainMainnetEmblem,
  [Network.WORLDCHAIN_SEPOLIA]: WorldChainTestnetEmblem,
  [Network.ZETACHAIN_MAINNET]: ZetachainMainnetEmblem,
  [Network.ZETACHAIN_TESTNET]: ZetachainTestnetEmblem,
  [Network.BLAST_MAINNET]: BlastMainnetEmblem,
  [Network.BLAST_SEPOLIA]: BlastTestnetEmblem,
  [Network.SCROLL_MAINNET]: ScrollMainnetEmblem,
  [Network.SCROLL_SEPOLIA]: ScrollTestnetEmblem,
  [Network.LINEA_MAINNET]: LineaMainnetEmblem,
  [Network.LINEA_SEPOLIA]: LineaTestnetEmblem,
  [Network.GNOSIS_MAINNET]: GnosisMainnetEmblem,
  [Network.GNOSIS_CHIADO]: GnosisTestnetEmblem,
  [Network.FANTOM_MAINNET]: FantomMainnetEmblem,
  [Network.FANTOM_TESTNET]: FantomTestnetEmblem,
  [Network.MANTLE_MAINNET]: MantleMainnetEmblem,
  [Network.MANTLE_SEPOLIA]: MantleTestnetEmblem,
  [Network.CELO_MAINNET]: CeloMainnetEmblem,
  [Network.CELO_ALFAJORES]: CeloTestnetEmblem,
  [Network.BERACHAIN_ARTIO]: BerachainTestnetEmblem,
  [Network.BERACHAIN_BARTIO]: BerachainTestnetEmblem,
  [Network.METIS_MAINNET]: MetisMainnetEmblem,
  [Network.OPBNB_MAINNET]: BnbMainnetEmblem,
  [Network.OPBNB_TESTNET]: BnbTestnetEmblem,
  [Network.POLYNOMIAL_MAINNET]: PolynomialMainnetEmblem,
  [Network.POLYNOMIAL_SEPOLIA]: PolynomialTestnetEmblem,
  [Network.SHAPE_MAINNET]: ShapeMainnetEmblem,
  [Network.SHAPE_SEPOLIA]: ShapeTestnetEmblem,
  [Network.BOB_MAINNET]: BobMainnetEmblem,
  [Network.CROSSFI_TESTNET]: CrossFiTestnetEmblem,
  [Network.IOTA_MAINNET]: IotaMainnetEmblem,
  [Network.IOTA_TESTNET]: IotaTestnetEmblem,
  [Network.MODE_MAINNET]: ModeMainnetEmblem,
  [Network.MODE_SEPOLIA]: ModeTestnetEmblem,
  [Network.MOONBASE_ALPHA]: MoonbaseTestnetEmblem,
  [Network.MOONBEAM_MAINNET]: MoonbeamMainnetEmblem,
  [Network.MOONRIVER_MAINNET]: MoonriverMainnetEmblem,
  [Network.PALM_MAINNET]: PalmMainnetEmblem,
  [Network.PALM_TESTNET]: PalmTestnetEmblem,
  [Network.SHIDEN_MAINNET]: ShidenMainnetEmblem,
  [Network.SHIDEN_SHIBUYA]: ShidenTestnetEmblem,
};

/**
 * @deprecated Use NetworkEmblem with a dynamically loaded NetworkInfo
 */
const networkEmblems = mapValues(networkEmblemUrls, (url, network) =>
  createEmblemComponent(url, NETWORK_INFO[+network as Network].name),
);

export default networkEmblems;
