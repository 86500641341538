import { NetworkInfo } from "@util/constants";
import { Emblem } from "./emblem";
import { networkEmblemUrls } from "./networkEmblems";

interface NetworkEmblemProps extends React.HTMLAttributes<HTMLDivElement> {
  networkInfo: Pick<NetworkInfo, "name" | "network">;
}

const NetworkEmblem = ({ networkInfo, ...props }: NetworkEmblemProps) => {
  const { name, network } = networkInfo;
  return (
    <Emblem imageAlt={name} imageUrl={networkEmblemUrls[network]} {...props} />
  );
};

export default NetworkEmblem;
