import ChainEmblem from "@components/ui/icons/ChainEmblem";
import { ChainInfo } from "@util/constants";

interface ChainLabelProps {
  chainInfo: ChainInfo;
}

function ChainLabel({ chainInfo }: ChainLabelProps) {
  return (
    <span className="flex min-w-0 items-center gap-2">
      <ChainEmblem chainInfo={chainInfo} className="size-6" />
      <span className="truncate">{chainInfo.name}</span>
    </span>
  );
}

export default ChainLabel;
