import { uniq } from "lodash";
import { useMemo } from "react";
import { z } from "zod";

import { Chain, ChainInfo, Network, NetworkInfo } from "@util/constants";

import { ComposerChainMethods, ComposerMethod } from "./methods";
import {
  buildParamDefaultValue,
  buildParamValueSchema,
  normalizeParamName,
} from "./params";

export const composerFormSchemaForMethod = (
  method: ComposerMethod,
  options: { strict: boolean } = { strict: true },
) => {
  return z.object({
    chain: z.nativeEnum(Chain),
    network: z.nativeEnum(Network),
    method: z.string(),
    categories: z.array(z.string()),
    query: z.object(
      Object.fromEntries(
        method.queryParams?.map((param) => [
          normalizeParamName(param.name || ""),
          buildParamValueSchema(param, options),
        ]) || [],
      ),
    ),
    body: method.requestBody
      ? buildParamValueSchema(method.requestBody, options)
      : z.object({}),
  });
};

export type ComposerFormState = z.infer<
  ReturnType<typeof composerFormSchemaForMethod>
>;

export const defaultValuesForMethod = ({
  methods,
  networkInfo,
  methodName,
}: {
  methods: ComposerChainMethods;
  networkInfo: NetworkInfo;
  methodName: string;
}): ComposerFormState => {
  const chain = networkInfo.chain;
  const method = methods[chain][methodName];

  return {
    chain,
    network: networkInfo.network,
    method: methodName,
    categories: [],
    query: Object.fromEntries(
      method.queryParams?.map((param) => [
        normalizeParamName(param.name || ""),
        buildParamDefaultValue(param),
      ]) || [],
    ),
    body: buildParamDefaultValue(method.requestBody) || {},
  };
};

export const useComposerNetworkSelect = ({
  methods,
  chainInfos,
  networkInfos,
}: {
  methods: ComposerChainMethods;
  chainInfos: ChainInfo[];
  networkInfos: NetworkInfo[];
}) => {
  // All networks that are supported by at least one method
  const composerNetworks = useMemo(
    () =>
      uniq(
        Object.values(methods).flatMap((chainMethods) =>
          Object.values(chainMethods).flatMap((method) => method.networks),
        ),
      ),
    [methods],
  );

  // Networks that are supported for both the user and the composer
  const composerNetworkInfos = networkInfos.filter((n) =>
    composerNetworks.includes(n.network),
  );

  // All chains that are supported by at least one method
  const composerChainInfos = chainInfos.filter((c) =>
    composerNetworkInfos.some((n) => n.chainId === c.id),
  );

  return {
    composerNetworkInfos,
    composerChainInfos,
  };
};
