import { ChainInfo } from "@util/constants";
import { chainEmblemUrls } from "./chainEmblems";
import { Emblem } from "./emblem";

interface ChainEmblemProps extends React.HTMLAttributes<HTMLDivElement> {
  chainInfo: Pick<ChainInfo, "name" | "chain">;
}

const ChainEmblem = ({ chainInfo, ...props }: ChainEmblemProps) => {
  const { name, chain } = chainInfo;
  return (
    <Emblem imageAlt={name} imageUrl={chainEmblemUrls[chain]} {...props} />
  );
};

export default ChainEmblem;
