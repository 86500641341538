import { z } from "zod";

const Recipient = z.object({
  userId: z.number(),
  teamId: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  isAdmin: z.boolean(),
});
type Recipient = z.infer<typeof Recipient>;

enum RecipientGroup {
  ALL = "ALL",
  ADMIN = "ADMIN",
}

enum ErrorAlertInterval {
  MINUTES_2 = "MINUTES_2",
  MINUTES_5 = "MINUTES_5",
  MINUTES_10 = "MINUTES_10",
  MINUTES_15 = "MINUTES_15",
}

enum ErrorAlertFrequency {
  MINUTES_2 = "MINUTES_2",
  MINUTES_5 = "MINUTES_5",
  MINUTES_10 = "MINUTES_10",
  MINUTES_15 = "MINUTES_15",
  MINUTES_30 = "MINUTES_30",
  HOURS_1 = "HOURS_1",
  HOURS_4 = "HOURS_4",
  HOURS_12 = "HOURS_12",
  HOURS_24 = "HOURS_24",
}

enum ErrorAlertType {
  ERROR_RATE = "ERROR_RATE",
}

enum UsageAlertType {
  ON_DEMAND_CU = "ON_DEMAND_CU",
  ON_DEMAND_DOLLAR = "ON_DEMAND_DOLLAR",
}

enum AlertKind {
  ERROR = "ERROR",
  USAGE = "USAGE",
}

enum ChannelType {
  UNSPECIFIED = "UNSPECIFIED",
  SLACK = "SLACK",
  TELEGRAM = "TELEGRAM",
}

const BaseAlert = z.object({
  id: z.number(),
  teamId: z.number(),
  active: z.boolean(),
  name: z.string(),
  recipients: z.array(Recipient),
  recipientGroups: z.array(z.nativeEnum(RecipientGroup)),
  vipChannel: z.nativeEnum(ChannelType).optional(),
});
type BaseAlert = z.infer<typeof BaseAlert>;

const ErrorAlert = BaseAlert.merge(
  z.object({
    type: z.nativeEnum(ErrorAlertType),
    interval: z.nativeEnum(ErrorAlertInterval),
    frequency: z.nativeEnum(ErrorAlertFrequency),
    percentThreshold: z
      .number()
      .int()
      .positive()
      // max basis points, i.e. 100.00%
      .max(10000),
    kind: z.literal(AlertKind.ERROR),
  }),
);
type ErrorAlert = z.infer<typeof ErrorAlert>;

const UsageAlert = BaseAlert.merge(
  z.object({
    type: z.nativeEnum(UsageAlertType),
    threshold: z.number(),
    kind: z.literal(AlertKind.USAGE),
  }),
);
type UsageAlert = z.infer<typeof UsageAlert>;

const Alert = z.discriminatedUnion("type", [ErrorAlert, UsageAlert]);
type Alert = z.infer<typeof Alert>;

export {
  Alert,
  AlertKind,
  BaseAlert,
  ChannelType,
  ErrorAlert,
  ErrorAlertFrequency,
  ErrorAlertInterval,
  ErrorAlertType,
  Recipient,
  RecipientGroup,
  UsageAlert,
  UsageAlertType,
};
