import { Chain } from "@alch/dx-webapp-api/shared";
import { mapValues } from "lodash";

import {
  ArbMainnetEmblem,
  ArbNovaMainnetEmblem,
  AstarMainnetEmblem,
  AvaxMainnetEmblem,
  BaseMainnetEmblem,
  BerachainMainnetEmblem,
  BlastMainnetEmblem,
  BnbMainnetEmblem,
  BobMainnetEmblem,
  CeloMainnetEmblem,
  CrossFiMainnetEmblem,
  EthMainnetEmblem,
  FantomMainnetEmblem,
  FraxMainnetEmblem,
  GnosisMainnetEmblem,
  IotaMainnetEmblem,
  LineaMainnetEmblem,
  MantleMainnetEmblem,
  MetisMainnetEmblem,
  ModeMainnetEmblem,
  MoonbaseMainnetEmblem,
  MoonbeamMainnetEmblem,
  MoonriverMainnetEmblem,
  OptMainnetEmblem,
  PalmMainnetEmblem,
  PlaceholderMainnetEmblem,
  PolygonMainnetEmblem,
  PolygonZkEvmMainnetEmblem,
  PolynomialMainnetEmblem,
  ScrollMainnetEmblem,
  ShapeMainnetEmblem,
  ShidenMainnetEmblem,
  SolanaMainnetEmblem,
  StarknetMainnetEmblem,
  WorldChainMainnetEmblem,
  ZetachainMainnetEmblem,
  ZkSyncMainnetEmblem,
  ZoraMainnetEmblem,
} from "@alch/ui/assets/networks";

import { CHAIN_INFO } from "@util/constants";
import { createEmblemComponent } from "./emblem";

/**
 * Round chain logo for each chain.
 *
 * This is in a separate file from constants.ts to improve tree shaking.
 */
export const chainEmblemUrls: Record<Chain, string> = {
  [Chain.ETH]: EthMainnetEmblem,
  [Chain.ARB]: ArbMainnetEmblem,
  [Chain.MATIC]: PolygonMainnetEmblem,
  [Chain.OPT]: OptMainnetEmblem,
  [Chain.SOLANA]: SolanaMainnetEmblem,
  [Chain.ASTAR]: AstarMainnetEmblem,
  [Chain.POLYGONZKEVM]: PolygonZkEvmMainnetEmblem,
  [Chain.STARKNET]: StarknetMainnetEmblem,
  [Chain.BASE]: BaseMainnetEmblem,
  [Chain.ZKSYNC]: ZkSyncMainnetEmblem,
  [Chain.ZORA]: ZoraMainnetEmblem,
  [Chain.FRAX]: FraxMainnetEmblem,
  [Chain.BNB]: BnbMainnetEmblem,
  [Chain.AVAX]: AvaxMainnetEmblem,
  [Chain.ARBNOVA]: ArbNovaMainnetEmblem,
  [Chain.ALCHEMY]: PlaceholderMainnetEmblem,
  [Chain.WORLDCHAIN]: WorldChainMainnetEmblem,
  [Chain.ZETACHAIN]: ZetachainMainnetEmblem,
  [Chain.BLAST]: BlastMainnetEmblem,
  [Chain.SCROLL]: ScrollMainnetEmblem,
  [Chain.LINEA]: LineaMainnetEmblem,
  [Chain.GNOSIS]: GnosisMainnetEmblem,
  [Chain.FANTOM]: FantomMainnetEmblem,
  [Chain.MANTLE]: MantleMainnetEmblem,
  [Chain.CELO]: CeloMainnetEmblem,
  [Chain.BERACHAIN]: BerachainMainnetEmblem,
  [Chain.METIS]: MetisMainnetEmblem,
  [Chain.OPBNB]: BnbMainnetEmblem,
  [Chain.POLYNOMIAL]: PolynomialMainnetEmblem,
  [Chain.SHAPE]: ShapeMainnetEmblem,
  [Chain.BOB]: BobMainnetEmblem,
  [Chain.CROSSFI]: CrossFiMainnetEmblem,
  [Chain.IOTA]: IotaMainnetEmblem,
  [Chain.MODE]: ModeMainnetEmblem,
  [Chain.MOONBASE]: MoonbaseMainnetEmblem,
  [Chain.MOONBEAM]: MoonbeamMainnetEmblem,
  [Chain.MOONRIVER]: MoonriverMainnetEmblem,
  [Chain.PALM]: PalmMainnetEmblem,
  [Chain.SHIDEN]: ShidenMainnetEmblem,
};

const chainEmblems = mapValues(chainEmblemUrls, (url, chain) =>
  createEmblemComponent(url, CHAIN_INFO[chain as Chain].name),
);

export default chainEmblems;
