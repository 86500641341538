import { FieldLabel, Link } from "@alch/ui";
import { useMemo } from "react";
import { Control, useController, useWatch } from "react-hook-form";

import { analyticsEvent, EventPrefix } from "@util/analytics";
import { SimpleToggleGroup } from "../../components/ui/index";

import { categoriesForChain } from "./lib/categories";
import { ComposerFormState } from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";

interface ComposerCategoriesProps {
  control: Control<ComposerFormState>;
  methods: ComposerChainMethods;
}

const ComposerCategories = ({ control, methods }: ComposerCategoriesProps) => {
  const { field } = useController({ control, name: "categories" });
  const selectedChain = useWatch({ control, name: "chain" });

  const chainCategories = useMemo(
    () => categoriesForChain(methods[selectedChain]),
    [selectedChain, methods],
  );

  return (
    <div>
      <div className="mb-3 mt-8 flex gap-2">
        <FieldLabel className="m-0" label="Category" />

        {field.value.length > 0 && (
          <Link
            size="sm"
            className="text-label-100"
            onClick={() => field.onChange([])}
          >
            unselect all
          </Link>
        )}
      </div>

      <SimpleToggleGroup
        type="multiple"
        value={field.value}
        onValueChange={(values) => {
          field.onChange(values);

          // Only track selections, not deselection
          if (field.value.length > values.length || values.length === 0) return;

          analyticsEvent(`${EventPrefix.Sandbox}: Selected category`, {
            category: values.at(-1),
          });
        }}
        items={chainCategories.map((category) => ({
          ...category,
          icon: (
            <img
              src={category.iconUrl}
              alt={category.label}
              className="size-5"
            />
          ),
        }))}
      />
    </div>
  );
};

export default ComposerCategories;
