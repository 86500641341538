import { Loading } from "@alch/ui";
import PageHeader from "@features/page-header";
import { DEPLOY_ENV } from "@util/deployEnv";
import { Path } from "@util/paths";

import ReactQueryErrorView from "@components/ui/react-query-error-view/ReactQueryErrorView";
import { ComposerChainMethods, useComposerMethods } from "./lib/methods";

import { useChainInfos } from "@queries/useChain";
import { useNetworkInfos } from "@queries/useNetwork";
import ComposerForm from "./ComposerForm";
import { parseSchema } from "./lib/schema";

const defaultBaseUrl = {
  production: `https://dashboard.alchemy.com${Path.Sandbox}`,
  staging: `https://dashboard.alchemypreview.com${Path.Sandbox}`,
  development: `http://localhost:8001${Path.Sandbox}`,
}[DEPLOY_ENV];

interface ComposerProps {
  baseUrl?: string;
}

const Composer = ({ baseUrl }: ComposerProps) => {
  const chainInfos = useChainInfos();
  const networkInfos = useNetworkInfos();
  const methods = useComposerMethods({
    select: (rawSchema) =>
      parseSchema({ rawSchema, networkInfos: networkInfos.data || [] }),
  });

  const queries = [methods, chainInfos, networkInfos];

  return (
    <div>
      {baseUrl ? null : <PageHeader title="Sandbox" />}

      {queries.some((q) => q.isPending) ? (
        <Loading className="mx-auto mt-4" />
      ) : queries.some((q) => q === undefined) ? (
        <ReactQueryErrorView results={queries} />
      ) : (
        <ComposerForm
          baseUrl={baseUrl || defaultBaseUrl}
          methods={methods.data || ({} as ComposerChainMethods)}
          chainInfos={chainInfos.data || []}
          networkInfos={networkInfos.data || []}
        />
      )}
    </div>
  );
};

export default Composer;
