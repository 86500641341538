import { Chain } from "@alch/dx-webapp-api/shared";
import chainEmblems from "@components/ui/icons/chainEmblems";
import usePaywalledNetworks from "@hooks/usePaywalledNetworks";
import { ChainInfo } from "@util/constants";
import { sortBy } from "lodash";
import { useMemo } from "react";
import ChainLabel from "./ChainLabel";

export interface ChainItem {
  value: Chain;
  label: string;
  icon: React.ElementType;
  displayLabel: React.ReactNode;
}

export default function useChainItems(chainInfos: ChainInfo[]): ChainItem[] {
  const paywalledNetworks = usePaywalledNetworks();

  return useMemo(
    () =>
      sortBy(Object.values(chainInfos), (c) =>
        // If order is -1 (not found), put it at the end
        c.order === -1 ? Number.MAX_SAFE_INTEGER : c.order,
      )
        .filter((chainInfo) =>
          chainInfo.networks.every(
            (network) => !paywalledNetworks.data?.includes(network),
          ),
        )
        .map((chainInfo) => ({
          value: chainInfo.chain,
          label: chainInfo.name,
          icon: chainEmblems[chainInfo.chain],
          displayLabel: <ChainLabel chainInfo={chainInfo} />,
        })),
    [chainInfos, paywalledNetworks.data],
  );
}
