import assertNever from "assert-never";
import { range, sortBy } from "lodash";
import { z } from "zod";

interface BaseComposerParam<Value> {
  name?: string;
  description?: string;
  required?: boolean;
  default?: Value | null;
  enum?: Value[];
}

export interface StringComposerParam extends BaseComposerParam<string> {
  type: "string";
  pattern?: string;
}

export interface NumberComposerParam extends BaseComposerParam<number> {
  type: "integer" | "number";
}

export interface BooleanComposerParam extends BaseComposerParam<boolean> {
  type: "boolean";
}

export interface ArrayComposerParam<Item, Value>
  extends BaseComposerParam<Value[]> {
  type: "array";
  items: Item;
  min?: number;
  max?: number;
}

export interface TupleComposerParam<Item, Value>
  extends BaseComposerParam<Value[]> {
  type: "tuple";
  items: Item[];
}

export interface ObjectComposerParam<Item, Value>
  extends BaseComposerParam<Record<string, Value>> {
  type: "object";
  properties: Record<string, Item>;
}

export interface OneOfComposerParam<Item, Value>
  extends BaseComposerParam<Value> {
  type: "oneOf" | "anyOf";
  items: Item[];
}

export type PrimitiveComposerParam =
  | StringComposerParam
  | NumberComposerParam
  | BooleanComposerParam;

export type PrimitiveComposerParamValue = PrimitiveComposerParam["default"];
export type ComposerParamValue =
  | PrimitiveComposerParamValue
  | Array<ComposerParamValue>
  | { [key: string]: ComposerParamValue };

export type ComposerParam =
  | PrimitiveComposerParam
  | ArrayComposerParam<ComposerParam, ComposerParamValue>
  | TupleComposerParam<ComposerParam, ComposerParamValue>
  | ObjectComposerParam<ComposerParam, ComposerParamValue>
  | OneOfComposerParam<ComposerParam, ComposerParamValue>;

export type ComposerParamType = ComposerParam["type"];

export type ComposerParamByType<Type extends ComposerParamType> = Extract<
  ComposerParam,
  { type: Type }
>;
export type ComposerParamValueByType<Type extends ComposerParamType> =
  ComposerParamByType<Type>["default"];

export const normalizeParamName = (name: string) => name.replaceAll("[]", "");

export const PRIMITIVE_PARAMS: ComposerParamType[] = [
  "string",
  "integer",
  "boolean",
] satisfies PrimitiveComposerParam["type"][];

export const sortOneOfParams = (params: ComposerParam[]) => {
  return sortBy(params, (param) =>
    param.enum ? -3 : param.type === "boolean" ? -2 : 0,
  );
};

export const buildParamValueSchema = <
  Type extends ComposerParamType,
  Param extends ComposerParam = Extract<ComposerParam, { type: Type }>,
  Value extends ComposerParamValue = ComposerParamValueByType<Type>,
>(
  param: Param,
  options: { strict: boolean } = { strict: true },
): z.Schema<Value> => {
  const schema = (() => {
    if (!param) {
      return z.null();
    }

    switch (param.type) {
      case "string": {
        if (param.enum && param.enum.length > 0) {
          const base = z.enum(param.enum as [string, ...string[]]);
          return options.strict && param.required ? base : base.nullable();
        }

        const base =
          options.strict && param.required
            ? z.string().nonempty("Required")
            : z.string().nullable();

        return base.refine((val) => {
          if (!val || !options.strict) return true;

          try {
            const regex = param.pattern ? new RegExp(param.pattern) : null;

            return regex ? regex.test(val) : true;
          } catch {
            return true;
          }
        });
      }
      case "integer":
      case "number":
        return z.coerce.number().nullable();
      case "boolean":
        return z.boolean().nullable();
      case "array":
        // Empty arrays are omitted from query params. Need to manually convert undefined into empty array.
        return z
          .array(buildParamValueSchema(param.items, options))
          .optional()
          .transform((val) => val ?? []);
      case "tuple":
        return z.tuple(
          param.items.map((item) => buildParamValueSchema(item, options)) as [
            z.ZodTypeAny,
            ...z.ZodTypeAny[],
          ],
        );
      case "object":
        return z.object(
          Object.fromEntries(
            Object.entries(param.properties).map(
              ([key, prop]): [string, z.Schema] => [
                normalizeParamName(key),
                buildParamValueSchema(prop, options),
              ],
            ),
          ),
        );
      case "oneOf":
      case "anyOf":
        return z.union(
          sortOneOfParams(param.items).map((item) =>
            buildParamValueSchema(item, options),
          ) as [z.Schema, z.Schema, ...z.Schema[]],
        );
      default:
        return assertNever(param);
    }
  })();

  return schema as unknown as z.Schema<Value>;
};

export const buildParamDefaultValue = (
  param?: ComposerParam,
): ComposerParamValue => {
  if (!param) {
    return undefined;
  }

  switch (param.type) {
    case "string":
      if (Array.isArray(param.default) && param.default[0]) {
        return String(param.default[0]);
      }

      return param.default ?? (param.enum?.length ? null : "");
    case "integer":
    case "number":
      return param.default || null;
    case "boolean":
      return typeof param.default === "boolean" ? param.default : null;
    case "array":
      if (Array.isArray(param.default)) {
        return param.default;
      }

      return range(param.min || 0).map(() =>
        buildParamDefaultValue(param.items),
      );
    case "tuple":
      if (Array.isArray(param.default)) {
        return param.default;
      }

      return param.items.map((param) => buildParamDefaultValue(param));
    case "object":
      return Object.fromEntries(
        Object.entries(param.properties).map(([key, prop]) => [
          normalizeParamName(key),
          buildParamDefaultValue(prop),
        ]),
      );
    case "oneOf":
    case "anyOf":
      return buildParamDefaultValue(param.items[0]);
    default:
      return assertNever(param);
  }
};
