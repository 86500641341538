import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { ChainInfo, Network, NetworkInfo } from "@util/constants";
import { useEffectOnUpdate } from "@util/hooks";

import { methodsForCategories } from "./lib/categories";
import {
  composerFormSchemaForMethod,
  defaultValuesForMethod,
} from "./lib/form";
import { ComposerChainMethods } from "./lib/methods";
import { parseStateFromShareUrl } from "./lib/sharing";

const useComposerForm = ({
  methods,
  search,
  networkInfos,
  chainInfos,
}: {
  methods: ComposerChainMethods;
  search: string;
  networkInfos: NetworkInfo[];
  chainInfos: ChainInfo[];
}) => {
  const shareState = parseStateFromShareUrl({ methods, search, networkInfos });

  const defaultNetwork = shareState ? shareState.network : Network.ETH_MAINNET;
  const defaultNetworkInfo =
    networkInfos.find((info) => info.network === defaultNetwork) ||
    networkInfos[0];
  const defaultChain = defaultNetworkInfo.chain;
  const defaultMethodName = shareState
    ? shareState.method
    : "eth_getBlockByNumber";
  const defaultMethod = methods[defaultChain][defaultMethodName];
  const defaultValues = Object.assign(
    {},
    defaultValuesForMethod({
      methods,
      networkInfo: defaultNetworkInfo,
      methodName: defaultMethodName,
    }),
    shareState,
  );

  const [schema, setSchema] = useState(() =>
    composerFormSchemaForMethod(defaultMethod),
  );

  const form = useForm({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const chain = useWatch({ control: form.control, name: "chain" });
  const network = useWatch({ control: form.control, name: "network" });
  const categories = useWatch({ control: form.control, name: "categories" });
  const method = useWatch({ control: form.control, name: "method" });

  const resetForm = (networkOverwrite: Network) => {
    const chainMethod = methods[chain][method];
    const networkInfo =
      networkInfos.find((n) => n.network === networkOverwrite) ||
      networkInfos[0];
    form.reset({
      ...defaultValuesForMethod({ methods, networkInfo, methodName: method }),
      categories,
    });
    setSchema(composerFormSchemaForMethod(chainMethod));
  };

  useEffectOnUpdate(() => {
    const chainInfo =
      chainInfos.find((info) => info.chain === chain) || chainInfos[0];
    const chainDefaultNetwork = chainInfo.defaultNetwork;
    const chainMethods = methods[chain];

    if (chainMethods[method]) {
      // We need to reset form state even if the method is the same as schema definitions might vary between chains
      resetForm(chainDefaultNetwork);
    } else {
      form.setValue("network", chainDefaultNetwork);
      form.setValue("categories", []);
      form.setValue("method", Object.keys(chainMethods)[0]);
    }
  }, [chain]);

  useEffectOnUpdate(() => {
    const categoryMethods = methodsForCategories(methods[chain], categories);

    if (!categoryMethods[method]) {
      form.setValue("method", Object.keys(categoryMethods)[0]);
    }
  }, [categories]);

  useEffectOnUpdate(() => {
    resetForm(network);
  }, [method]);

  return form;
};

export default useComposerForm;
