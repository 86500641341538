import { Button, IconButton } from "@alch/ui";
import { AlchemyMarkOnLight } from "@alch/ui/assets/logos";
import { ArrowUpRightSm } from "@alch/ui/icons/16";

import { analyticsEvent, EventPrefix } from "@util/analytics";
import { Url } from "@util/urls";

interface ComposerStandaloneLayoutProps {
  children: React.ReactNode;
}

const ComposerStandaloneLayout = ({
  children,
}: ComposerStandaloneLayoutProps) => {
  return (
    <div className="px-4 pb-4 lg:px-6 lg:pb-6">
      <div className="mx-auto max-w-screen-2xl text-grayscale-950">
        <div className="mb-1 flex items-center gap-2 py-4">
          <IconButton as="a" href={Url.Home} target="_blank" rounded="slight">
            <img
              src={AlchemyMarkOnLight}
              alt="Alchemy Logo"
              style={{ width: 26, height: 22 }}
            />
          </IconButton>

          <h2 className="mr-auto font-mono text-code-300">Sandbox</h2>

          <div className="mr-1 bg-decorative-gradient-blue bg-clip-text text-paragraph-200-regular font-medium text-transparent max-md:hidden">
            The most reliable way to build web3 apps
          </div>

          <Button
            size="sm"
            trailingIcon={<ArrowUpRightSm />}
            as="a"
            href={Url.Home}
            target="_blank"
            onClick={() => {
              analyticsEvent(
                `${EventPrefix.Sandbox}: Clicked "Sign up for Alchemy"`,
              );
            }}
          >
            Sign up for Alchemy
          </Button>
        </div>

        {children}
      </div>
    </div>
  );
};

export default ComposerStandaloneLayout;
