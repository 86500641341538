import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Chain, Network } from "@util/constants";

import { type ComposerParam } from "./params";
import { type RawSchema } from "./schema";

export type ComposerMethod = {
  category: string;
  description?: string;
  docsUrl?: string;
  networks: Network[];
  method: string;
  url: string;
  queryParams?: ComposerParam[];
  requestBody?: ComposerParam;
};

export type ComposerChainMethods = Record<
  Chain,
  Record<string, ComposerMethod>
>;

export const useComposerMethods = <T = RawSchema>(
  opts?: Partial<UseQueryOptions<RawSchema, Error, T>>,
) => {
  return useQuery<RawSchema, Error, T>({
    ...opts,
    queryKey: ["composer-methods"],
    queryFn: async () => {
      const response = await fetch("https://sandbox.alchemy.com/schema.json");
      return response.json() as Promise<RawSchema>;
    },
  });
};
