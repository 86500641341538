import { Product } from "@alch/dx-webapp-api/shared";

enum SettingsTab {
  AccessKeys = "access-keys",
  Profile = "profile",
  Team = "team",
}

enum Path {
  Home = "/",
  Apps = "/apps",
  CreateApp = `${Path.Apps}/new`,
  Products = "/products",
  ChatWeb3 = "/chat-web-3",
  ChatWeb3Chat = `${Path.ChatWeb3}/c`,
  Logout = "/logout",
  Logs = "/logs",
  NewLogs = "/new-logs",
  Sandbox = "/sandbox",
  SubgraphsRedirect = "/redirect/subgraphs",
  Support = "/support",
  Mempool = "/mempool",
  Usage = "/usage",
  Webhooks = "/webhooks",
  AlertsHub = "/alerts",

  // Chain Services
  Rollups = "/rollups",

  // Wallet Services
  GasManager = "/gas-manager",
  GasManagerPolicy = `${Path.GasManager}/policy`,
  GasManagerPolicyCreate = `${Path.GasManagerPolicy}/create`,
  Accounts = "/accounts",
  AccountsConfigCreate = `${Path.Accounts}/config/create`,

  // Data Platform
  DataPlatform = "/pipelines",
  DataPlatformCreate = "/pipelines/create",

  // Settings
  Settings = "/settings",
  AccessKeys = `${Path.Settings}/${SettingsTab.AccessKeys}`,
  Billing = `${Path.Settings}/billing`,
  BillingSpendingCap = `${Path.Billing}#spending-cap`,
  BillingAutoScaling = `${Path.Billing}#auto-scaling`,
  PaymentDetails = `${Path.Billing}`,
  Downgrade = `${Path.Settings}/billing/downgrade`,
  Profile = `${Path.Settings}/${SettingsTab.Profile}`,
  Team = `${Path.Settings}/${SettingsTab.Team}`,
  ConfigureAlerts = `${Path.Settings}/alerts`,

  //Signup
  Signup = "/signup",
  SignupTeam = `${Path.Signup}/team`,
  SignupProject = `${Path.Signup}/project`,
}

const pathBuilder = {
  appDetails: (
    id: string,
    tab?: "setup" | "metrics" | "networks" | "settings",
  ) => `${Path.Apps}/${id}${tab == null ? "" : `/${tab}`}`,
  chatWeb3: {
    chat: (id: string) => `${Path.ChatWeb3Chat}/${id}`,
  },
  gasManager: {
    editPolicy: (id: string) => `${Path.GasManagerPolicy}/${id}/edit`,
    reviewPolicy: (id: string) => `${Path.GasManagerPolicy}/${id}`,
  },
  accounts: {
    editConfig: (id: string) => `${Path.Accounts}/config/${id}/edit`,
  },
  alerts: {
    editAlert: (id: number) => `${Path.ConfigureAlerts}/${id}`,
  },
  chainServices: {
    rollupDetails: (raasId: string) => `${Path.Rollups}/${raasId}`,
  },
  dataPlatform: {
    pipeline: (id: string | number) => `${Path.DataPlatform}/pipeline/${id}`,
  },
  logs: {
    perApp: (appId: string) => `${Path.Logs}/${appId}`,
  },
  usage: {
    perApp: (appId: string) => `${Path.Usage}/${appId}`,
  },
  products: {
    landingPage: (product: Product) => `${Path.Products}/${product}`,
  },
};

export { Path, pathBuilder, SettingsTab };
