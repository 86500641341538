import classNames from "classnames";
import { useCallback } from "react";
import { useController } from "react-hook-form";
import { TextField } from "../../../components/ui/index";

import { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";

const ComposerIntegerParam = ({
  control,
  name,
  param,
}: ComposerParamComponentProps<"integer" | "number">) => {
  const { field } = useController({ control, name });

  const customOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;

      field.onChange(value === "" || Number.isNaN(+value) ? null : +value);
    },
    [field],
  );

  return (
    <TextField
      type="number"
      inputContainerClassName={classNames("w-36")}
      control={control}
      name={name}
      size="sm"
      onChange={customOnChange}
      value={field.value == null ? "" : (field.value as number)}
      label={param.name && <ComposerParamLabel unwrap param={param} />}
      formatValue={(value) => String(value || "")}
    />
  );
};

export default ComposerIntegerParam;
