import { FieldInfo } from "@alch/ui";
import { useController } from "react-hook-form";

import { SimpleToggleGroup, TextField } from "../../../components/ui/index";

import { type ComposerParamComponentProps } from ".";
import ComposerParamLabel from "./ComposerParamLabel";

const ComposerStringParam = ({
  param,
  control,
  name,
}: ComposerParamComponentProps<"string">) => {
  const { field, fieldState } = useController({ control, name });

  if (param.enum) {
    return (
      <div>
        <ComposerParamLabel param={param} />

        <SimpleToggleGroup
          className="mt-2 first:mt-0"
          type="single"
          value={String(field.value)}
          onValueChange={(value) => field.onChange(value || null)}
          items={param.enum.map((item) => ({
            label: String(item),
            value: item,
          }))}
        />

        <FieldInfo
          errorMessage={fieldState.error?.message}
          description={undefined}
        />
      </div>
    );
  }

  return (
    <TextField
      control={control}
      name={name}
      size="sm"
      label={param.name && <ComposerParamLabel unwrap param={param} />}
      formatValue={(value) => String(value || "")}
    />
  );
};

export default ComposerStringParam;
