import assertNever from "assert-never";
import { Control } from "react-hook-form";

import { ComposerParamByType, ComposerParamType } from "../lib/params";

import ComposerBooleanParam from "./ComposerBooleanParam";
import ComposerIntegerParam from "./ComposerIntegerParam";
import ComposerStringParam from "./ComposerStringParam";
/* eslint-disable import/no-cycle -- recursive imports are intentional */
import ComposerArrayParam from "./ComposerArrayParam";
import ComposerObjectParam from "./ComposerObjectParam";
import ComposerOneOfParam from "./ComposerOneOfParam";
import ComposerTupleParam from "./ComposerTupleParam";
/* eslint-enable import/no-cycle */

export type ComposerParamComponentProps<Type extends ComposerParamType> = {
  control: Control;
  name: string;
  param: ComposerParamByType<Type>;
};

interface ComposerParamInputProps
  extends ComposerParamComponentProps<ComposerParamType> {
  nestedIndex?: number;
}

const ComposerParamInput = ({
  control,
  name,
  param,
  nestedIndex,
}: ComposerParamInputProps) => {
  if (!param) {
    return null;
  }

  switch (param.type) {
    case "string":
      return (
        <ComposerStringParam control={control} name={name} param={param} />
      );
    case "integer":
    case "number":
      return (
        <ComposerIntegerParam control={control} name={name} param={param} />
      );
    case "boolean":
      return (
        <ComposerBooleanParam control={control} name={name} param={param} />
      );
    case "array":
      return (
        <ComposerArrayParam
          control={control}
          name={name}
          param={param}
          nestedIndex={nestedIndex}
        />
      );
    case "tuple":
      return (
        <ComposerTupleParam
          control={control}
          name={name}
          param={param}
          nestedIndex={nestedIndex}
        />
      );
    case "object":
      return (
        <ComposerObjectParam
          control={control}
          name={name}
          param={param}
          nestedIndex={nestedIndex}
        />
      );
    case "oneOf":
    case "anyOf":
      return (
        <ComposerOneOfParam
          control={control}
          name={name}
          param={param}
          nestedIndex={nestedIndex}
        />
      );
    default:
      assertNever(param);
  }
};

export default ComposerParamInput;
