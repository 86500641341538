import { useLocation } from "react-router-dom";

export enum SearchParam {
  RedirectUrl = "redirectUrl",
  AuthToken = "authToken",
  TestSignup = "test_signup",
  // During checkout, tell the server to fail the CC fingerprint check
  TestFailFingerprint = "test_fail_fingerprint",
  RedirectAttempt = "redirect_attempt",
  TestCheckoutSuccess = "test_checkout_success",
  TestPlan = "test_plan",
  TestTerm = "test_term",
  TestUsageCapType = "test_usage_cap_type",
  TestSignupProjectType = "test_signup_project_type",
  TestRecommendedProduct = "test_recommended_product",
  TestUpgradeOnboarding = "test_upgrade_onboarding",
  TestUseNetworkError = "test_use_network_error",
}

const useSearchParam = (searchParam: SearchParam) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get(searchParam);
};

export default useSearchParam;
