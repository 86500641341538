import { ChainId } from "@alch/dx-webapp-api/shared";
import useAvailableChains from "@hooks/useAvailableChains";
import { useQuery } from "@tanstack/react-query";
import { CHAIN_INFO, ChainInfo, CHAINS } from "@util/constants";
import { useCallback } from "react";
import { z } from "zod";

const CHAIN_INFO_CACHE_KEY = ["chain-info"];

/**
 * Temporary call, pretending that we are calling the backend chain info endpoint
 */
const simulateFetchChainInfo = async () => {
  console.log("calling simulateFetchChainInfo...");
  await new Promise((r) => setTimeout(r, 600));
  return CHAINS.map((chain) => CHAIN_INFO[chain]);
};

export function useChainInfos() {
  const availableChains = useAvailableChains();

  const select = useCallback(
    (chainInfos: ChainInfo[]) => {
      return chainInfos.filter((chainInfo) =>
        // TODO: Remove this when plugging into the backend, which will filter flagged networks
        // at the API level
        availableChains.includes(chainInfo.chain),
      );
    },
    [availableChains],
  );

  return useQuery<ChainInfo[], Error>({
    queryKey: CHAIN_INFO_CACHE_KEY,
    queryFn: simulateFetchChainInfo,
    select,
  });
}

export function createChainValidator(chainInfos: ChainInfo[]) {
  return z.string().refine((chainId): chainId is ChainId => {
    return chainInfos.some((info) => info.id === chainId);
  });
}
