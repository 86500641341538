import { CodeBox } from "@alch/ui";
import { stringify } from "qs";
import { Control, useWatch } from "react-hook-form";

import { ComposerFormState } from "./lib/form";
import { ComposerMethod } from "./lib/methods";
import { nullifyEmptyValues } from "./lib/sharing";

interface ComposerRequestPreviewProps {
  className?: string;
  control: Control<ComposerFormState>;
  method: ComposerMethod;
  url: string;
}

const ComposerRequestPreview = ({
  className,
  control,
  method,
  url,
}: ComposerRequestPreviewProps) => {
  const query = useWatch({ control, name: "query" });
  const body = useWatch({ control, name: "body" }) as unknown;

  return (
    <CodeBox
      className={className}
      title="Request preview"
      snippets={[
        {
          wrapLongLines: true,
          language: method.requestBody ? "json" : "python",
          code: method.requestBody
            ? JSON.stringify(nullifyEmptyValues(body), null, 2)
            : `${url}${stringify(nullifyEmptyValues(query), {
                addQueryPrefix: true,
                skipNulls: true,
                arrayFormat: "brackets",
              })}`,
        },
      ]}
    />
  );
};

export default ComposerRequestPreview;
